export default class ExpiredSessionError extends Error{
  constructor(){
    super();
    this.message = 'Your session has expired';
  }

  toString(){
    return this.message;
  }
}
