import {useEffect, useState} from 'react';
import { set, cloneDeep } from '../libs/lodash';
import utils from '../libs/utils';

export default function useForm(initialVals, callback) {
  const [values, setValues] = useState(initialVals || {});
  const [formValidated, setFormValidated] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      setValues(initialVals);
    }

    return () => {
      cancel = true;
    };
  }, [initialVals]);

  const handleCancelForm = () => {
    setValues(initialVals);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    const buttonSelector = 'button[type=\'submit\']';

    try {
      event.preventDefault();
      event.stopPropagation();
      setFormValidated(true);
      const inputs = form.querySelectorAll('input:invalid, select:invalid, textarea:invalid, input.is-invalid');

      if (form.checkValidity() === true && inputs.length === 0) {
        utils.prepareDisabledButton(form, buttonSelector, true);
        setFormValidated(false);
        await callback();
      } else {
        form.classList.add('was-validated');

        // scroll to the first error element
        const elemId = inputs[0].getAttribute('id');
        if (elemId && elemId.indexOf('file-') !== -1) {
          document.getElementById(elemId).parentElement.scrollIntoView({block: 'end', behavior: 'smooth'});
        } else {
          (elemId ? document.getElementById(elemId) : inputs[0]).scrollIntoView({block: 'end', behavior: 'smooth'});
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      utils.prepareDisabledButton(form, buttonSelector, false);
    }
  };

  const handleChange = (updateData) => {
    const keys = Object.keys(updateData);
    const ids = values.notifyTargetIds || [];

    if (keys.includes('mentionUserId')) {
      const mentionUserId = updateData.mentionUserId;
      if(!ids.includes(mentionUserId)) {
        ids.push(mentionUserId);
        updateData.notifyTargetIds = ids;
        delete updateData.mentionUserId;
      } else if (keys.length === 1){
        return;
      }
    }

    const newValues = cloneDeep(values);
    Object.keys(updateData).forEach((key) => {
      set(newValues, key, updateData[key]);
    });
    setValues({...values, ...newValues});
  };

  return {
    handleChange,
    handleSubmit,
    values,
    formValidated, 
    handleCancelForm
  };
};
