const countryCurrencyLookup = {
  'United States': 'USD',
  'United Kingdom': 'GBP',
  'Canada': 'CAD',
  'India': 'INR',
  'Brazil': 'BRL',
  'Singapore': 'SGD'
};

export {
  countryCurrencyLookup
};
