import React from 'react';
import AtsInput from './AtsInput';
import AtsTypeAheadSearchSelect from './AtsTypeAheadSearchSelect';
import Country from './Country';
import FormFields from './FormFields';
import {get, isEmpty} from '../../libs/lodash';
import layoutConst from '../../constants/layoutConst';
import useLookup from '../../hooks/useLookup';
import AtsCheckBox from './AtsCheckBox';
import AtsSelect from './AtsSelect';
import ApiClient from '../../services/ApiClient';
import { prepareAutoFillFromJob } from '../Placements/libs/utils';

function Location({object, rateInfo, namePrefix, formValidated, callback, idUniq, requiredFields={}, showCountryField=false, remoteCheckBox={}, workModeValue, showWorkModeField}) {
  const ltColCss = layoutConst.ltColCss;
  const rtColCss = layoutConst.rtColCss;
  const fullColCss = layoutConst.fullColCss;
  const idPrefix = idUniq ? namePrefix.replace('.', '-') : '';
  const lookups = useLookup({names: ['countries']});

  if(object.state === null) {
    object.city = null;
  }

  const remoteCheckBoxCallback = async () => {
    if (!remoteCheckBox.value && remoteCheckBox.resetFields && isEmpty(remoteCheckBox.resetFields.workLocation) && remoteCheckBox.candidateId) {
      const res2 = await ApiClient.candidatesDetails({id: remoteCheckBox.candidateId, saveActivity: false});
      remoteCheckBox.resetFields.workLocation = res2.body.additionalInfo.address;
    }

    const resetFields = !remoteCheckBox.value ?  remoteCheckBox.resetFields : {};
    callback({
      ...resetFields || {},
      [remoteCheckBox.name]: !remoteCheckBox.value
    });
  };

  const workModeCallback = async (updatedData) => {
    const workLocation = {workLocation:{country: '', city: '', state: ''}};
    if (updatedData.workMode === 'WORK_FROM_HOME' ) {
      const billingLocationSameAsWorkLocation = false;
      if(remoteCheckBox.candidateId) {
        const res2 = await ApiClient.candidatesDetails({id: remoteCheckBox.candidateId, saveActivity: false});
        remoteCheckBox.resetFields.workLocation = res2.body.additionalInfo.address;
        callback({...updatedData, ...remoteCheckBox.resetFields, billingLocationSameAsWorkLocation});
        return;
      }
      callback({...updatedData, ...workLocation, billingLocationSameAsWorkLocation});
      return;
    } 
    if (updatedData.workMode === 'WORK_FROM_OFFICE' ) {
      const billingLocationSameAsWorkLocation = true;
      if(remoteCheckBox.jobId) {
        const res = await ApiClient.jobsDetails({id: remoteCheckBox.jobId, saveActivity: false});
        const autoFillFromJob = prepareAutoFillFromJob(res.body);
        callback({...updatedData, ...autoFillFromJob, billingLocationSameAsWorkLocation});
        return;
      }
      callback({...updatedData, ...workLocation, billingLocationSameAsWorkLocation});
      return;
    }
    callback(updatedData);
  };

  function isOtherCountry() {
    return object.country === 'Other' || (!!object.country && !(lookups.countries || []).some(country => country.name === object.country));
  }

  const workModeField = [
    [
      {
        label: 'Work Mode',
        className: ltColCss,
        component: AtsSelect,
        props: {
          id: 'workMode',
          name: 'workMode',
          options: lookups.placementWorkModes,
          defaultValue: workModeValue,
          callback: workModeCallback
        }
      }
    ]
  ];

  const cityStateFields = isOtherCountry() ? [
    {
      label: 'City',
      className: ltColCss,
      component: AtsInput,
      props: {
        id: idPrefix + 'city',
        name: namePrefix + 'city',
        defaultValue: object.city
      }
    },
    {
      label: 'State/ Province',
      className: rtColCss,
      component: AtsInput,
      props: {
        id: idPrefix + 'state',
        name: namePrefix + 'state',
        defaultValue: object.state
      }
    }
  ] : [
    {
      label: 'City',
      className: ltColCss,
      component: AtsTypeAheadSearchSelect,
      props: {
        id: idPrefix + 'city',
        name: namePrefix + 'city',
        isMulti: false,
        defaultValue: get(object, 'city') ? {'city': get(object, 'city') || '', 'state':  get(object, 'state') || '', 'location': ''} : '',
        required: false,
        labelKey: 'city',
        clearButton: false,
        minLength: 2,
        maxLengthToApi: 2,
        delay:0,
        placeholder: 'City',
        searchOperation: 'locationsSearch',
        filterBy: ['city', 'state', 'location'],
        allowNew: false,
        country: object.country,
        requiredFields: requiredFields,
        ...remoteCheckBox.name === 'isRemote' && {[remoteCheckBox.name]: remoteCheckBox.value}
      }
    },
    {
      label: 'State/ Province',
      className: rtColCss,
      component: AtsTypeAheadSearchSelect,
      props: {
        id: idPrefix + 'state',
        name: namePrefix + 'state',
        isMulti: false,
        defaultValue: get(object, 'state') ? {'state': get(object, 'state') || ''} : '',
        required: false,
        labelKey: 'state',
        clearButton: false,
        minLength:2,
        maxLengthToApi:2,
        delay:0,
        placeholder: 'State',
        searchOperation: 'locationsSearch',
        filterBy: ['state'],
        allowNew: false,
        country: object.country
      }
    }
  ];

  const locationFields = [
    [
      { label: 'Address 1',
        className: fullColCss,
        component: AtsInput,
        props: {
          id: idPrefix + 'address1',
          name: namePrefix + 'address1',
          defaultValue: object.address1,
          required: requiredFields.address1,
        }
      }
    ],
    [
      { label: 'Address 2',
        className: fullColCss,
        component: AtsInput,
        props: {
          id: idPrefix + 'address2',
          name: namePrefix + 'address2',
          defaultValue: object.address2,
          required: requiredFields.address2,
        }
      }
    ],
    cityStateFields
    ,
    [
      {
        label: 'Postal Code / Zip',
        className: ltColCss,
        component: AtsInput,
        props: {
          id: idPrefix + 'postalCode',
          name: namePrefix + 'postalCode',
          type: 'number',
          defaultValue: object.postalCode,
          required: requiredFields.postalCode,
        }
      }
    ]
  ];

  return (
    <>
      {!showCountryField ? '' : <Country object={object} rateInfo={rateInfo} namePrefix={namePrefix} formValidated={formValidated} callback={callback} idUniq={idUniq} requiredFields={requiredFields} />}

      { !remoteCheckBox.name ?
        ''
        :
        <div className='form-group pl-0 pt-3'>
          <AtsCheckBox
            name={remoteCheckBox.name}
            defaultValue={remoteCheckBox.value ? ['CHECKED'] : []}
            options={[{label: remoteCheckBox.label, value: 'CHECKED', identClass: 'checkboxClass'}]}
            handleChange={remoteCheckBoxCallback}
          />
        </div>
      }

      { !showWorkModeField ?
        ''
        :
        <FormFields 
          fields={workModeField}
          formValidated={formValidated}
          callback={callback}
        />
      }

      <FormFields
        fields={locationFields}
        formValidated={formValidated}
        callback={callback}
      />
    </>
  );
}

export default Location;
