import React from 'react';
import {
  cloneDeep,
  get,
  pick,
  has,
  isEmpty,
  compact,
  keyBy,
  groupBy,
  uniqBy,
  sortBy,
  difference,
  isArray,
} from '../../../libs/lodash';
import utils from '../../../libs/utils';
import highlights from '../../Search/SearchList/TableView/config/job/highlights';
import moment from 'moment';
import searchConst from '../../../constants/searchConst';
import {BadgeList} from '../../common';
import groupByOptions from '../../Search/SearchList/TableView/config/job/groupByOptions';
import daysOpenLookups from '../../Dashboard/MyJobs/JobColumnChart/config/daysOpenLookups';
import submissionConst from '../../../constants/submissionConst';

function prepareForRenderJobs(data) {
  data.ownerUserId = data.owner && data.owner.userId;
  data.primaryRecruiterUserId = data.primaryRecruiter && data.primaryRecruiter.userId;
  data.salespersonUserId = data.salesperson && data.salesperson.userId;
  data.accountManagerUserId = data.accountManager && data.accountManager.userId;
  data.accountCoordinatorUserId = data.accountCoordinator && data.accountCoordinator.userId;
  data.coRecruiterUserIds = (data.coRecruiters || []).map((coRecruiter) => coRecruiter.userId);
  data.practiceAreaId = data.practiceArea && data.practiceArea.id;
  data.businessUnitId = data.businessUnit && data.businessUnit.id;
  data.badgeIds = (data.badges || []).map((badge) => badge.id);
  data.companyId = data.company && data.company.companyId;
  data.company = {...data.company, entityType: 'COMPANY', entityId: data.companyId};
  //data.contactId = data.contact && data.contact.contactId;

  data.coding = utils.prepareCodingTransform(data.coding);

  if(data.postingInfo) {
    data.postingInfo.practiceAreaId = get(data, 'postingInfo.practiceArea.id') && data.postingInfo.practiceArea.id;
    data.postingInfo.businessUnitId = get(data, 'postingInfo.businessUnit.id') && data.postingInfo.businessUnit.id;
    const {userId, fullName} = get(data, 'postingInfo.recruiterInfo') || {};
    if (userId) {
      data.orgWebsiteRecruiter = {id: userId, name: fullName };
    }
  }

  if (!isEmpty(data.contact)) {
    data.contact.name = compact([
      get(data, 'contact.contactName.preferredName') || get(data, 'contact.contactName.firstName'),
      get(data, 'contact.contactName.lastName')
    ]).join(' ');
  }

  if (get(data, 'company.companyType') === 'PROGRAM') {
    data.programInfo = {
      ...data.programInfo,
      mspContact: {
        ...get(data, 'programInfo.mspContact'),
        name: compact([
          get(data, 'programInfo.mspContact.contactName.preferredName') || get(data, 'programInfo.mspContact.contactName.firstName'),
          get(data, 'programInfo.mspContact.contactName.lastName')
        ]).join(' ')
      },
      clientContact: {
        ...get(data, 'programInfo.clientContact'),
        name: compact([
          get(data, 'programInfo.clientContact.contactName.preferredName') || get(data, 'programInfo.clientContact.contactName.firstName'),
          get(data, 'programInfo.clientContact.contactName.lastName')
        ]).join(' '),
      }
    };
  }

  // deal for the inactive user
  data.origOwner = utils.convertObjectToOptionFormat(data.owner);
  data.origPrimaryRecruiter = utils.convertObjectToOptionFormat(data.primaryRecruiter);
  data.origCoRecruiters = utils.convertObjectToOptionFormat(data.coRecruiters);
  data.origSalesperson = utils.convertObjectToOptionFormat(data.salesperson);
  data.origAccountManager = utils.convertObjectToOptionFormat(data.accountManager);
  data.origAccountCoordinator = utils.convertObjectToOptionFormat(data.accountCoordinator);

  return data;
}

function prepareForApiJobs(data) {
  const apiData = pick(cloneDeep(data), [
    'jobTitle',
    'companyId',
    'contactId',
    'businessUnitId',
    'practiceAreaId',
    'badgeIds',
    'openStatus',
    'closeReason',
    'jobSummary',
    'jobDescription',
    'postingStatus',
    'postedAt',
    'numOpenPositions',
    'submittalCap',
    'priority',
    'accountManagerUserId',
    'accountCoordinatorUserId',
    'minYearsExperience',
    'maxYearsExperience',
    'experienceLevel',
    'ownerUserId',
    'primaryRecruiterUserId',
    'coRecruiterUserIds',
    'salespersonUserId',
    'expectedStartDate',
    'expectedEndDate',
    'vmsInfo',
    'patientCareType',
    'complianceStatus',
    'location',
    'jobType',
    'rateInfo',
    'coding.industryIds',
    'coding.jobFunctionIds',
    'coding.departmentIds',
    'coding.skillIds',
    'coding.highlightedSkills',
    'coding.parsedDescSkills',
    'coding.phcCareerCodeIds',
    'coding.phcFacilityTypeIds',
    'degreeRequirements',
    'benefits',
    'supplementalDocuments',
    'rejectAllActiveSubmissions',
    'auditLogComment',
    'postingInfo',
    'isRemote',
    'jobSource'
  ]);

  if (has(apiData, 'postingInfo.businessUnit')) {
    delete apiData.postingInfo.businessUnit;
  }
  if (has(apiData, 'postingInfo.practiceArea')) {
    delete apiData.postingInfo.practiceArea;
  }

  if(get(apiData, 'postingInfo.jobDescription')) {
    apiData.postingInfo.jobDescription = encodeURIComponent(get(apiData, 'postingInfo.jobDescription'));
  }

  const tinyMCEFields = ['jobDescription', 'degreeRequirements', 'benefits', 'auditLogComment'];
  tinyMCEFields.forEach(function(field) {
    if (apiData[field]) {
      apiData[field] = encodeURIComponent(apiData[field]);
    }
  });

  //force id utils to number format
  if(has(data, 'companyId') && apiData.companyId) {
    apiData.companyId = +apiData.companyId;
  }

  if(has(data, 'contactId') && apiData.contactId) {
    apiData.contactId = +apiData.contactId;
  }

  if (!isEmpty(data.contact)) {
    apiData.contactId = get(data, 'contact.contactId');
  }

  if(has(data, 'ownerUserId') && apiData.ownerUserId) {
    apiData.ownerUserId = +apiData.ownerUserId;
  }

  if (has(apiData, 'supplementalDocuments')) {
    for (let i = 0; i < apiData.supplementalDocuments.length; i++) {
      apiData.supplementalDocuments[i] = {attachmentId: +apiData.supplementalDocuments[i].attachmentId};
    }
  }

  if (apiData.rejectAllActiveSubmissions === false) {
    delete apiData.rejectAllActiveSubmissions;
  }

  if (get(apiData, 'expectedStartDate')) {
    apiData.expectedStartDate = utils.dateOnlyForApi(apiData.expectedStartDate);
  }

  if (get(apiData, 'expectedEndDate')) {
    apiData.expectedEndDate = utils.dateOnlyForApi(apiData.expectedEndDate, false);
  }

  // fields changed, there is no script for data migration
  // these to take care these
  if (has(apiData, 'rateInfo.isNonexempt')) {
    delete apiData.rateInfo.isNonexempt;
  }

  if (has(apiData, 'rateInfo.hoursPerWeek')) {
    delete apiData.rateInfo.hoursPerWeek;
  }
  // end

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  if (get(apiData, 'location')) {
    apiData.location= utils.addressTransform(apiData.location);
  }

  if (get(apiData, 'postingInfo.location')) {
    apiData.postingInfo.location= utils.addressTransform(apiData.postingInfo.location);
  }


  if(has(data, 'programInfo')) {
    if (get(data, 'company.companyType') === 'PROGRAM') {
      apiData.programInfo = {
        mspCompanyId: get(data, 'programInfo.mspCompany.companyId'),
        mspContactId: get(data, 'programInfo.mspContact.contactId'),
        clientCompanyId: get(data, 'programInfo.clientCompany.companyId'),
        clientContactId: get(data, 'programInfo.clientContact.contactId')
      };

      apiData.contactId = get(data, 'programInfo.mspContact.contactId');
    } else {
      apiData.programInfo = {
        mspCompanyId: null,
        mspContactId: null,
        clientCompanyId: null,
        clientContactId: null
      };
    }
  }

  return apiData;
}

function prepareForSearchApiJobs(data) {
  const apiData = cloneDeep(data);
  try {
    apiData.searchResultsFieldsList = searchConst.defaultSearchResultsFieldsListLookup['job'];
  } catch (err) {
    console.log(err);
  } finally {
    return apiData;
  }
}

function prepareForSearchRenderJobs(data) {
  try {
    data = data.map((job) => {
      job.jobTitleLink =
        <>
          <div data-title={job.jobTitle}>
            <a href={`/job/${job.jobId}`} target='_blank' rel='noreferrer'
              onClick={() => window.amplitudeLogEvent('view', { action: 'view job', object: 'job', globalNav:'', from: 'jobs widget', detailsTab: 'details'})}
            >
              {job.jobTitle}
            </a>
          </div>
        </>;

      job.badgeList = <BadgeList badges={job.badges || []} width='11' height='13' />;

      const differenceInTime = new Date().getTime() - new Date(job.createdAt).getTime();
      job.daysOpen = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      job.remainingNumOpenPositions = get(job, 'numOpenPositions', 0) - get(job, 'metadata.submissions.numByStage[5].numTotalExculdeRejected', 0);
      job.remainingNumOpenPositions = job.remainingNumOpenPositions < 0 ? 0 : job.remainingNumOpenPositions;
      job.numTotal = get(job, 'metadata.submissions.numByStage[2].numTotal', 0) + get(job, 'metadata.submissions.numByStage[3].numTotal', 0) + get(job, 'metadata.submissions.numByStage[4].numTotal', 0);
      job.isCovered = utils.validateIsCovered(job);
      job.numActive = get(job, 'metadata.submissions.numActive', 0);
      job.numClientReview = get(job, 'metadata.submissions.numByStage[2].numTotalExculdeRejected', 0);
      job.numInterview = get(job, 'metadata.submissions.numByStage[3].numTotalExculdeRejected', 0);
      job.numOffer = get(job, 'metadata.submissions.numByStage[4].numTotalExculdeRejected', 0) + get(job, 'metadata.submissions.numByStage[5].numTotalExculdeRejected', 0);
      job.stage = get(job, 'metadata.submissions.latestStageStatus', 'PRE_SUBMISSION').replace('_REJECTED', '');

      return job;
    });

    return data;
  } catch (err) {
    console.log(err);
    return data;
  }
}

function prepareForRecentViewedEntityJobs(data) {
  try {
    const {city, state} = get(data, 'location') || data;
    const recentVisitData = {
      ...pick(cloneDeep(data), [
        'jobTitle',
        'company',
        'openStatus',
        'openStatusValue',
        'jobId',
        'bullhornId'
      ]),
      city, state
    };

    recentVisitData.statusValue = recentVisitData.openStatusValue ? recentVisitData.openStatusValue : utils.camelize(recentVisitData.openStatus);
    recentVisitData.entityType = 'JOB';
    recentVisitData.entityId = recentVisitData.jobId;
    recentVisitData.stringEntityId = 'JOB' + recentVisitData.entityId;
    recentVisitData.recentViewedEntity = true;
    return recentVisitData;
  } catch (err) {
    console.log(err);
    return data;
  }
}

function interviewToPlacedRate(job) {
  try {
    const interviewingCount = get(job, 'data.metadata.submissions.numByStage[3].numTotal', 0);
    const offeredCount = get(job, 'data.metadata.submissions.numByStage[4].numTotal', 0);
    const placedCount = get(job, 'data.metadata.submissions.numByStage[5].numTotal', 0);
    const total = offeredCount + interviewingCount + placedCount;
    if (total) {
      return Math.round((placedCount / total) * 100);
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
}

function offeredRate(job){
  try {
    const interviewingCount = get(job, 'data.metadata.submissions.numByStage[3].numTotal', 0);
    const offeredCount = get(job, 'data.metadata.submissions.numByStage[4].numTotal', 0);
    const placedCount = get(job, 'data.metadata.submissions.numByStage[5].numTotal', 0);
    const total = interviewingCount + offeredCount + placedCount;
    if (total) {
      return Math.round(((offeredCount + placedCount) / total) * 100);
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
}

function placedRate(job){
  try {
    const offeredCount = get(job, 'data.metadata.submissions.numByStage[4].numTotal', 0);
    const placedCount = get(job, 'data.metadata.submissions.numByStage[5].numTotal', 0);
    const total = offeredCount + placedCount;
    if (total) {
      return Math.round((placedCount / total) * 100);
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
}

function numTotalRejected(job) {
  try {
    const sumOfRejected = get(job, 'data.metadata.submissions.totalReject');

    return sumOfRejected;
  } catch (err) {
    console.log(err);
    return 0;
  }
}

function numTotalClientRejected(job) {
  try {
    const sumOfClientRejected = get(job, 'data.metadata.submissions.totalClientReject');

    return sumOfClientRejected;
  } catch (err) {
    console.log(err);
    return 0;
  }
}

function totalClientSubmission(job) {
  return (
    get(job, 'data.metadata.submissions.numByStage[2].numTotal', 0) +
    get(job, 'data.metadata.submissions.numByStage[3].numTotal', 0) +
    get(job, 'data.metadata.submissions.numByStage[4].numTotal', 0) +
    get(job, 'data.metadata.submissions.numByStage[5].numTotal', 0)
  );
}

function workDayCount(start, end) {
  const from = start.clone().endOf('week');
  const to = end.clone().startOf('week');
  const days = to.diff(from, 'days') * 5 / 7;

  let firstWeekDays = from.day() - start.day();
  let lastWeekDays  = end.day() - to.day();

  if (from.day() === 0) {
    firstWeekDays--;
  }

  if (to.day() === 6) {
    lastWeekDays--;
  }

  return firstWeekDays + Math.floor(days) + lastWeekDays;
}

function timeToSubmit(job, stage) {
  try {
    let timeToSubmit = '';
    job = job.data ? job.data : job;
    const submissionsByStageHistory = get(job, 'submissionsByStageHistory', []);
    const byStageHistory = keyBy(submissionsByStageHistory, 'stageHistory.stage');

    if (byStageHistory[stage]) {
      const createdAt = get(byStageHistory[stage], 'stageHistory.createdAt');
      const jobCreatedAt = get(byStageHistory[stage], 'stageHistory.job.createdAt');
      if (createdAt && jobCreatedAt) {
        timeToSubmit = workDayCount(moment.utc(jobCreatedAt), moment.utc(createdAt));
      }
    }

    return timeToSubmit;
  } catch (err) {
    console.log(err);
    return '';
  }
}

function calculateHighlights(data) {
  let result = {};
  try {
    highlights.forEach((h) => {
      result[h.value] = 0;
    });
    result['NUM_COVERAGE_NOT'] = 0;

    let countClient = 0;
    let countInterview = 0;
    let countOffered = 0;
    let countPlaced = 0;

    data.forEach((job) => {
      result['NUM_POSITIONS'] += job.numOpenPositions;
      const remainingPositions = get(job, 'numOpenPositions', 0) - get(job, 'metadata.submissions.numByStage[5].numTotalExculdeRejected', 0);
      result['NUM_REMAINING_POSITIONS'] += remainingPositions < 0 ? 0 : get(job, 'numOpenPositions', 0) - get(job, 'metadata.submissions.numByStage[5].numTotalExculdeRejected', 0);
      const isCoverage = utils.validateIsCovered(job);

      if (isCoverage) {
        result['NUM_COVERAGE'] += 1;
      } else {
        result['NUM_COVERAGE_NOT'] += 1;
      }

      result['NUM_NEW_LEAD'] += job.openStatus === 'NEW_LEAD' ? 1 : 0;
      result['NUM_OPEN'] += job.openStatus === 'OPEN' ? 1 : 0;
      result['NUM_COVERED'] += job.openStatus === 'COVERED' ? 1 : 0;
      result['NUM_ONHOLD'] += job.openStatus === 'ONHOLD' ? 1 : 0;
      result['NUM_PIPELINE'] += job.openStatus === 'PIPELINE' ? 1 : 0;
      result['NUM_EXPIRED'] += job.openStatus === 'EXPIRED' ? 1 : 0;
      result['NUM_CLOSED'] += job.openStatus === 'CLOSED' ? 1 : 0;

      const timeToClientReview = timeToSubmit(job, 'CLIENT_REVIEW');
      if (timeToClientReview) {
        result['DAYS_CLIENT_REVIEW'] += timeToClientReview;
        countClient++;
      }

      const timeToInterview = timeToSubmit(job, 'INTERVIEWING');
      if (timeToInterview) {
        result['DAYS_INTERVIEW'] += timeToInterview;
        countInterview++;
      }

      const timeToOffered = timeToSubmit(job, 'OFFERED');
      if (timeToOffered) {
        result['DAYS_OFFER'] += timeToOffered;
        countOffered++;
      }

      const timeToPlaced = timeToSubmit(job, 'PLACED');
      if (timeToPlaced) {
        result['DAYS_HIRE'] += timeToPlaced;
        countPlaced++;
      }

      result['NUM_PRE_SUBMISSION'] += get(job, 'metadata.submissions.numByStage[0].numTotalExculdeRejected', 0);
      result['NUM_INTERNAL_REVIEW'] += get(job, 'metadata.submissions.numByStage[1].numTotalExculdeRejected', 0);
      result['NUM_CLIENT_REVIEW'] += get(job, 'metadata.submissions.numByStage[2].numTotalExculdeRejected', 0);
      result['NUM_INTERVIEWING'] += get(job, 'metadata.submissions.numByStage[3].numTotalExculdeRejected', 0);
      result['NUM_OFFERED'] += get(job, 'metadata.submissions.numByStage[4].numTotalExculdeRejected', 0);
      result['NUM_PLACED'] += get(job, 'metadata.submissions.numByStage[5].numTotalExculdeRejected', 0);
      result['NUM_PAST_INTERVIEWS'] += get(job, 'pastInterviewsCount', 0);
      result['NUM_UPCOMING_INTERVIEWS'] += get(job, 'upcomingInterviewsCount', 0);
    });

    result['NUM_REMAINING_POSITIONS'] = result['NUM_REMAINING_POSITIONS'] < -1 ? 0 : result['NUM_REMAINING_POSITIONS'];

    if (countClient) {
      result['DAYS_CLIENT_REVIEW'] = Math.round(result['DAYS_CLIENT_REVIEW'] / countClient);
    }

    if (countInterview) {
      result['DAYS_INTERVIEW'] = Math.round(result['DAYS_INTERVIEW'] / countInterview);
    }

    if (countOffered) {
      result['DAYS_OFFER'] = Math.round(result['DAYS_OFFER'] / countOffered);
    }

    if (countPlaced) {
      result['DAYS_HIRE'] = Math.round(result['DAYS_HIRE'] / countPlaced);
    }

    const totalResults = result['NUM_COVERAGE'] + result['NUM_COVERAGE_NOT'];

    if (totalResults) {
      result['NUM_COVERAGE'] = Math.round((result['NUM_COVERAGE'] / totalResults) * 100) + '%';
      result['NUM_COVERAGE_NOT'] = Math.round((result['NUM_COVERAGE_NOT'] / totalResults) * 100) + '%';
    }

    return result;
  } catch (err) {
    console.log(err);
    return result;
  }
}

function calculateNumOfJobByGroup(jobs, groupByName, initData, lookups) {
  try {
    let numJobs = [], maxJobs = 0, keys, valueAttrName, entityTypeAttrName, identity;

    const groupByOptionsKeyBy = keyBy(groupByOptions, 'id');
    const groupByOption = groupByOptionsKeyBy[groupByName] || {};
    keys = Object.keys(keyBy(lookups[groupByOption.lookupsKey] || [], 'id'));
    valueAttrName = groupByOption.valueAttr;
    entityTypeAttrName = groupByOption.entityTypeAttr;

    switch (groupByName) {
    case 'daysOpen':
      Object.keys(daysOpenLookups).forEach((key, index) => {
        const from = new Date();
        if (daysOpenLookups[key].from) {
          from.setDate(from.getDate() + daysOpenLookups[key].from);
        }
        const to = new Date();
        if (daysOpenLookups[key].to) {
          to.setDate(to.getDate() + daysOpenLookups[key].to);
        }

        let jobsCount;
        if (key !== '31+') {
          jobsCount = jobs.filter((job) => {
            if (Date.parse(utils.dateForApi(from, true)) <= Date.parse(job.createdAt) && Date.parse(job.createdAt) <= Date.parse(utils.dateForApi(to, false))) {
              return true;
            } else {
              return false;
            }
          }).length;
        } else {
          jobsCount = jobs.filter(job => Date.parse(job.createdAt) < Date.parse(utils.dateForApi(to, false))).length;
        }
        maxJobs = jobsCount > maxJobs ? jobsCount : maxJobs;

        numJobs.push({
          filterBy: key,
          filterByLabel: daysOpenLookups[key].filterByLabel,
          num: jobsCount
        });
      });
      break;
    case 'openStatus':
    case 'stage':
    case 'jobType':
    case 'rateInfo.overtimeType':
    case 'rateInfo.currencyType':
    case 'rateInfo.rateType':
    case 'practiceArea.id':
    case 'company.companyType':
    case 'patientCareType':
    case 'programInfo.clientCompany.companyId':
    case 'programInfo.mspCompany.companyId':
    case 'company.companyId':
    case 'location':
      let jobsGroupBy, jobsCount, label;
      if (groupByName === 'stage' && jobs.length && !jobs[0].daysOpen) {
        jobs = jobs.map((job) => {
          job.stage = get(job, 'metadata.submissions.latestStageStatus', 'PRE_SUBMISSION').replace('_REJECTED', '');
          return job;
        });
      }

      if (groupByName === 'location') {
        jobsGroupBy = groupBy(jobs, function(job) {
          return compact([job.location.city, job.location.state]).join(', ');
        });
      } else {
        jobsGroupBy = groupBy(jobs, groupByName);
      }

      if(isEmpty(keys)) {
        keys = Object.keys(jobsGroupBy);
        if(groupByName === 'stage') {
          keys = submissionConst.stages;
        }
      } else if (jobsGroupBy['undefined']) {
        keys.push('undefined');
      }

      (keys || []).forEach((key, index) => {
        if (key === 'undefined' && jobsGroupBy[null]) {
          jobsCount = (jobsGroupBy[key] || []).concat(jobsGroupBy[null] || []).length;
        } else {
          jobsCount = (jobsGroupBy[key] || []).length;
        }
        maxJobs = jobsCount > maxJobs ? jobsCount : maxJobs;

        if (valueAttrName) {
          label = get(jobsGroupBy[key][0], valueAttrName);
          label = (label && typeof label !== 'string' ? compact([(label || {}).preferredName || label.firstName, label.lastName]).join(' ') : label) || 'Not Specified';
        } else if (['stage', 'company.companyType'].includes(groupByName)) {
          label = utils.camelize(key);
        } else if (groupByName === 'location') {
          label = key || 'Not Specified';
        } else {
          label = (keyBy(lookups[groupByOption.lookupsKey], 'id')[key] || {}).name || 'Not Specified';
        }

        identity = entityTypeAttrName && label !== 'Not Specified' ? `\n(${entityTypeAttrName}${key})` : '';

        numJobs.push({
          filterBy: isNaN(parseInt(key)) ? key : +key,
          filterByLabel: utils.contentForRender(label, 30, false) + identity,
          num: jobsCount
        });
      });

      break;
    case 'clientContact':
    case 'mspContact':
      if (groupByName === 'clientContact') {
        jobs = jobs.map((job) => {
          job.jobContact = job.company.companyType === 'MSP' ? {} :  (job.programInfo || {}).clientContact || job.contact;
          return job;
        });
      }

      if (groupByName === 'mspContact') {
        jobs = jobs.map((job) => {
          job.jobContact = job.company.companyType === 'CLIENT' ? {} : (job.programInfo ||  {}).mspContact || job.contact;
          return job;
        });
      }

      const jobsGroupByContact = groupBy(jobs, 'jobContact.contactId');
      const contactKeys = Object.keys(jobsGroupByContact);
      (contactKeys || []).forEach((key, index) => {
        const jobsCount = (jobsGroupByContact[key] || []).length;
        maxJobs = jobsCount > maxJobs ? jobsCount : maxJobs;
        const name = get(jobsGroupByContact[key][0], 'jobContact.contactName');
        numJobs.push({
          filterBy: key === 'undefined' ? 'undefined' : +key,
          filterByLabel: key === 'undefined' ? 'Not Specified' : utils.contentForRender(compact([name.preferredName || name.firstName, name.lastName]).join(' '), 25, false),
          num: jobsCount
        });
      });

      break;
    case 'owner':
    case 'owner.userId':
      const jobsGroupByOwner = groupBy(jobs, 'owner.userId');
      const ownerKeys = Object.keys(jobsGroupByOwner);

      (ownerKeys || []).forEach((key, index) => {
        const jobsCount = (jobsGroupByOwner[key] || []).length;
        maxJobs = jobsCount > maxJobs ? jobsCount : maxJobs;
        const owner = get(jobsGroupByOwner[key][0], 'owner');
        numJobs.push({
          filterBy: +key,
          filterByLabel: utils.contentForRender(compact([owner.firstName, owner.lastName]).join(' '), 10, false),
          num: jobsCount
        });
      });

      break;
    case 'recruiters':
      let jobsGroupByRecruiter = groupBy(jobs, 'primaryRecruiter.userId');
      jobs.forEach((job) => {
        if(!isEmpty(job.coRecruiters)) {
          job.coRecruiters.forEach((r) => {
            if (isEmpty(jobsGroupByRecruiter[r.userId])) {
              jobsGroupByRecruiter[r.userId] = [job];
            } else {
              jobsGroupByRecruiter[r.userId] = uniqBy(jobsGroupByRecruiter[r.userId].concat([job]), 'jobId');
            }
          });
        }
      });

      (Object.keys(jobsGroupByRecruiter) || []).forEach((key, index) => {
        const jobsCount = (jobsGroupByRecruiter[key] || []).length;
        maxJobs = jobsCount > maxJobs ? jobsCount : maxJobs;
        const usersById = keyBy(uniqBy(compact(get(jobsGroupByRecruiter[key][0], 'coRecruiters').concat([get(jobsGroupByRecruiter[key][0], 'primaryRecruiter')])), 'userId'), 'userId');

        numJobs.push({
          filterBy: +key,
          filterByLabel: utils.contentForRender(compact([usersById[key].firstName, usersById[key].lastName]).join(' '), 10, false),
          num: jobsCount
        });
      });

      break;
    default:
    }

    if (!['daysOpen', 'openStatus', 'stage', 'jobType', 'rateInfo.overtimeType', 'rateInfo.currencyType', 'rateInfo.rateType', 'company.companyType', 'patientCareType'].includes(groupByName)) {
      numJobs = sortBy(numJobs, 'filterByLabel', 'asc');
    }

    return {data: numJobs, maxNum: maxJobs};
  } catch (err) {
    console.log(err);
    return {data: initData[groupByName] || [], maxNum: 1};
  }
}

function buildFilterCriteria(groupByName, selectedFilters, initFilters) {
  try {
    switch (groupByName) {
    case 'daysOpen':
      let dateCreatedRange = {'dateCreatedRange': ''};
      const lookups = daysOpenLookups;
      let from, to;
      selectedFilters.forEach((filterBy) => {
        if (from === undefined || (lookups[filterBy].from !== undefined && lookups[filterBy].from < from)) {
          from = lookups[filterBy].from;
        }

        if (to === undefined || (lookups[filterBy].to !== undefined && lookups[filterBy].to > to)) {
          to = lookups[filterBy].to;
        }
      });

      if (selectedFilters.indexOf('0-3') !== -1) {
        to = undefined;
      }

      if (selectedFilters.indexOf('31+') !== -1) {
        from = undefined;
      }

      const fromDate = new Date();
      if (from) {
        fromDate.setDate(fromDate.getDate() + from);
      }
      const toDate = new Date();
      if (to) {
        toDate.setDate(toDate.getDate() + to);
      }

      // build dateCreatedRange criteria
      if (from && to) {
        dateCreatedRange = {
          'dateCreatedRange': {
            option: 'between',
            value: [utils.dateForApi(fromDate, true), utils.dateForApi(toDate, false)]
          }
        };
      } else if (from) {
        dateCreatedRange = {'dateCreatedRange': {option: 'after', value: [utils.dateForApi(fromDate, true)]}};
      } else if (to) {
        dateCreatedRange = {'dateCreatedRange': {option: 'before', value: [utils.dateForApi(toDate, false)]}};
      }

      return {...initFilters, ...dateCreatedRange};
    case 'openStatus':
      return {...initFilters, openStatuses: selectedFilters};
    case 'stage':
      return {...initFilters, stages: selectedFilters};
    case 'owner':
      return {...initFilters, ownerUserIds: selectedFilters};
    case 'recruiters':
      return {...initFilters, recruiterUserIds: selectedFilters};
    default:
    }
  } catch (err) {
    console.log(err);
    return initFilters;
  }
}

function doesExternalFilterPass(job, filteredBy) {
  try {
    const filterCriteria = filteredBy.filterCriteria || {};
    const filteredFrom = filteredBy.filteredFrom;
    const keys = Object.keys(filterCriteria);

    if (!keys) {
      return true;
    }

    const key = keys[0];
    let value = filterCriteria[key];

    // no filter
    if (!value && value !== false) {
      return true;
    }

    const notSpecifiedIndex = isArray(value) ? value.indexOf('undefined') : -1;
    let realValue;
    switch (key) {
    case 'coverage':
      const jobIsCoverage = (
        get(job, 'metadata.submissions.numByStage[2].numTotal', 0) +
        get(job, 'metadata.submissions.numByStage[3].numTotal', 0) +
        get(job, 'metadata.submissions.numByStage[4].numTotal', 0) +
        get(job, 'metadata.submissions.numByStage[5].numTotal', 0)
      ) !== 0;
      if (value) {
        return jobIsCoverage;
      } else if (value === false) {
        return !jobIsCoverage;
      }
      break;
    case 'openStatus':
      realValue = get(job, key);
      return filteredFrom === 'highlights' ? value === realValue : value.includes(realValue);
    case 'location':
      realValue = compact([(job.location || {}).city, (job.location || {}).state]).join(', ');
      break;
    case 'daysOpen':
      const criteria = buildFilterCriteria('daysOpen', value, {});
      const option = get(criteria.dateCreatedRange, 'option');
      const searchValue = get(criteria.dateCreatedRange, 'value');
      switch (option) {
      case 'between':
        return Date.parse(value[0]) <= Date.parse(job.createdAt) && Date.parse(job.createdAt) <= Date.parse(searchValue[1]);
      case 'after':
        return Date.parse(job.createdAt) > Date.parse(searchValue[0]);
      case 'before':
        return Date.parse(job.createdAt) < Date.parse(searchValue[0]);
      default:
        return true;
      }
    case 'clientContact':
      realValue = (job.company.companyType === 'MSP' ? {} : job.programInfo.clientContact || job.contact).contactId;
      break;
    case 'mspContact':
      realValue = (job.company.companyType === 'CLIENT' ? {} : job.programInfo.mspContact || job.contact).contactId;
      break;
    case 'recruiters':
      const coRecruiterIds = (job.coRecruiters || []).map((user) => user.userId);
      const primaryRecruiterId = get(job, 'primaryRecruiter.userId');
      return value.includes(primaryRecruiterId) || difference(value, coRecruiterIds).length !== value.length;
    default:
      realValue = get(job, key);
    }

    if (notSpecifiedIndex !== -1) {
      value = [...value.slice(0, notSpecifiedIndex), ...value.slice(notSpecifiedIndex + 1)];
      return value.includes(realValue) || !realValue;
    } else {
      return value.includes(realValue);
    }
  } catch (err) {
    console.log(err);
  }
}

export {
  prepareForRenderJobs,
  prepareForApiJobs,
  prepareForSearchApiJobs,
  prepareForSearchRenderJobs,
  prepareForRecentViewedEntityJobs,
  interviewToPlacedRate,
  offeredRate,
  placedRate,
  numTotalRejected,
  numTotalClientRejected,
  totalClientSubmission,
  timeToSubmit,
  calculateHighlights,
  calculateNumOfJobByGroup,
  doesExternalFilterPass,
  buildFilterCriteria
};
