import './style.scss';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AtsSelect, Button, AtsRichTextArea, FormFields, AtsCheckBox} from '../common';
import useForm from '../../hooks/useForm';
import utils from '../../libs/utils';
import { prepareForRenderNoteActivity, prepareDefaultNote, prepareForApiNote } from './libs/utils';
import {isEmpty, difference, get} from '../../libs/lodash';
import AtsTypeAheadSelectAll from '../common/AtsTypeAheadSelectAll';
import * as activityActions from '../../actions/activity';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import usePrevious from '../../hooks/usePrevious';
import useLookup from '../../hooks/useLookup';
import useNotifyUserIds from '../../hooks/useNotifyUserIds';
import * as notificationActions from '../../actions/notification';
import * as submissionActions from '../../actions/submission';
import * as searchEntityActions from '../../actions/searchEntity';
import submissionConst from '../../constants/submissionConst';
import ApiClient from '../../services/ApiClient';
import actionTypes from '../../constants/actionTypes';
import { refreshStatusScreened } from '../../actions/activity';
import * as dealsActions from '../../actions/dealsActions';

export default function NoteForm(props) {
  const dispatch = useDispatch();
  const activityParams = useSelector(state => state.activityReducer.parameters);
  const submissionCriteria = useSelector(state => state.submissionReducer.parameters.criteria);
  const lookups = useLookup({ names: ['users', 'teams', 'activityTypes'] });

  const [notes] = useState(prepareDefaultNote(props));
  const [entityOptions] = useState([]);
  const [activityTypesOptions, setActivityTypesOptions] = useState([]);
  const [filterActivityTypesOptions, setFilterActivityTypesOptions] = useState([]);
  const prevActivityTypesOptions = usePrevious(entityOptions);
  const [taskCreation, setTaskCreation] = useState(false);

  const closeNote = () => {
    props.modalCancel();
    if(props.closeDrawer) {
      props.closeDrawer();
    }
  };

  const submitNote = async () => {
    const parameters = prepareForApiNote(values);
    try {
      const response = await ApiClient.notesCreate({}, parameters);
      const data = response.body;
      const noteActivity = prepareForRenderNoteActivity(data);

      if(props.saveCallback) {
        await props.saveCallback(noteActivity, props.entity.entityType);
      } else {
        window.amplitudeLogEvent('create', { action: 'save new note', object: 'note', type: noteActivity.activityType.name.toLowerCase(), globalNav: 'global add', method: 'global add', timer : 'end'});
      }

      const entityPath = '/' + values.entity.entityType.toLowerCase();
      const pathName = window.location.pathname.toLowerCase();
      if (!['/submissions'].includes(pathName) && (window.location.pathname.startsWith(entityPath) && values.entity.entityType === activityParams.entityType && +values.entity.entityId === +activityParams.entityId)){
        dispatch(submissionActions.fetchSubmission({offset: 0, maxResults: submissionConst.submissionTabMaxResults, criteria: submissionCriteria}));
        dispatch(searchEntityActions.getMetadata({entityType: activityParams.entityType.toLowerCase(), id: activityParams.entityId}));

        const activityCategoriesFilter = utils.getActivityFilterCategories(activityParams.entityType);
        if (data?.activityType?.activityTypeId === 26 && !activityCategoriesFilter?.includes('PRESCREEN')) {
          dispatch({
            type: actionTypes.activityActionTypes.ADD_PRESCREEN,
            payload: {
              prescreen : data
            }
          });

          // https://prideglobal.atlassian.net/browse/ATS-1727
          // check if any backend update of candidate status from UNSCREENED to SCREENED
          const isUnscreened = document.querySelector('#canHiddenStatus');
          if(isUnscreened) {
            dispatch(refreshStatusScreened(true));
          }
        } else if (isEmpty(activityCategoriesFilter) || activityCategoriesFilter?.includes(data.activityType?.category)){
          dispatch(activityActions.fetchRecentActivities());
        }
      }

      // ATS-1579 - Global Note Blocks Save and Doesnt Give Me Error - add props.entity check before access notification to fix the issue
      if (props.entity && props.entity.notification) {
        const parameters = {
          id: props.entity.notification.notificationId,
          notification: {
            isRead: true,
            hasReplied: true,
          }
        };
        dispatch(notificationActions.updateNotification(parameters));
      }

      const msg = 'Note Added';
      toast(msg, {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: 'top-right'
      });

      if (props.createTaskFromData && taskCreation) {
        props.createTaskFromData({...data, ...values});
      } else if (notes.entity.entityType === 'DEAL') {
        dispatch(dealsActions.getDeals({ companyId: props.relatedEntity.companyId }));
        closeNote();
      } else {
        closeNote();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {values, formValidated, handleChange, handleSubmit} = useForm(notes, submitNote);

  const {notifyTargetIdsField} = useNotifyUserIds({lookups, handleChange, entity: values});

  if (!isEmpty(lookups.activityTypes) && isEmpty(activityTypesOptions)){
    const formattedActivityTypes = lookups.activityTypes;
    const orderedActivityTypes = [];
    for (let i = 0; i < formattedActivityTypes.length; i++) {
      const fItem = formattedActivityTypes[i];
      if (orderedActivityTypes.length === 0 || fItem.name === 'General Note') {
        orderedActivityTypes.unshift(fItem);
        continue;
      }
      let j;
      for (j = 0; j < orderedActivityTypes.length; j++) {
        const oItem = orderedActivityTypes[j];
        if (oItem.name > fItem.name && oItem.name !== 'General Note') {
          orderedActivityTypes.splice(j, 0, fItem);
          break;
        }
      }
      if (j===orderedActivityTypes.length) {
        orderedActivityTypes.push(fItem);
      }
    }

    setActivityTypesOptions(orderedActivityTypes);
  }

  useEffect(() => {
    if (values.entity.entityType && !isEmpty(activityTypesOptions) && difference(activityTypesOptions, prevActivityTypesOptions).length !== 0) {
      const activityTypesOptionsFilter = activityTypesOptions.filter((activityType) => {
        return activityType.isActive &&
          activityType.entityTypes.includes(values.entity.entityType) &&
          activityType.actions.includes('NOTE');
      }).map((activityType) => {
        return utils.prepareActivityTypes(activityType);
      });

      setFilterActivityTypesOptions(activityTypesOptionsFilter);
    }
  }, [values.entity.entityType, activityTypesOptions, prevActivityTypesOptions]);

  return (
    <>
      <form id='note-form' name='note-form' noValidate onSubmit={handleSubmit} autoComplete='off'>
        {
          !props.disableLink ?
            <div className='form-group col-6 px-0'>
              <AtsTypeAheadSelectAll
                labelName='About'
                id='note-entity'
                fieldName='entity'
                entity={values.entity}
                formValidated={formValidated}
                handleChange={handleChange}
              />
            </div>
            : ''
        }
        <div className='form-group col-6 px-0'>
          <label htmlFor='type'>
            Activity Type
            <span className='required'>*</span>
          </label>
          {
            !props.activityTypeId ?
              <AtsSelect
                id='activityTypeId'
                name='activityTypeId'
                defaultValue={values.activityTypeId}
                required={true}
                options={filterActivityTypesOptions}
                validated={formValidated}
                callback={handleChange}
              />:
              <div className='biotif-semi-bold'>{get(filterActivityTypesOptions.find((activityType) => activityType.id === values.activityTypeId), 'name', '')}</div>
          }
        </div>
        <div className='form-group'>
          <AtsRichTextArea
            label={<label>Comment<span className='required'>*</span></label>}
            entityType={values.entity && values.entity.entityType ? values.entity.entityType : ''}
            initialValue={values.content || ''}
            handleChange={handleChange}
          />
        </div>
        {/* Notify Users or Teams */}
        <FormFields
          fields={notifyTargetIdsField}
          formValidated={formValidated}
          callback={handleChange}
        />
        <div className='form-group'>
          <div className='d-flex justify-content-end pt-3'>
            {
              props.createTaskFromData
                ? <AtsCheckBox
                  id={'note-form-task-creation'}
                  name='taskCreation'
                  defaultValue={taskCreation?['CHECKED']:[]}
                  options={[{label: 'Create a Task', value: 'CHECKED'}]}
                  handleChange={() => setTaskCreation(!taskCreation)} />
                : ''
            }
            <Button type='button' id='close-note-modal' variant='btn-secondary mr-4' click={() => closeNote()}>
              Cancel
            </Button>
            <Button type="submit" id='save-note' variant='btn-primary ats-md-btn'>
              Save
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

NoteForm.propTypes = {
  entity: PropTypes.object,
  clearForm: PropTypes.func,
  saveCallback: PropTypes.func
};
