import dateSearchConst from './dateSearchConst';
import {keyBy} from '../libs/lodash';

const maxResults = 100;
const delay = 400;
const minLength = 2;
const bulkActionLimitCount = 100;
const sendEmailLimitCount = 1000;
const addToListLimitCount = 1000;

const below = 'below';
const above = 'above';
const between = dateSearchConst.between;
const withValue = 'with';
const withoutValue = 'without';

const entityTypeAheadFilterBy = [
  'fullName', 'generalEmail', 'otherEmail', 'mobilePhone', 'homePhone',
  'workPhone', 'city', 'state', 'statusValue', 'jobTitle', 'companyName',
  'openStatusValue', 'jobType', 'name', 'searchId', 'searchStringId',
  'vmsJobId', 'vmsPlacementId', 'bullhornId'
];

const dateRangeChoicesKeyBy = keyBy(dateSearchConst.dateRangeChoices, 'id');
const searchOptions = {
  'date': [
    dateRangeChoicesKeyBy[dateSearchConst.before],
    dateRangeChoicesKeyBy[dateSearchConst.after],
    dateRangeChoicesKeyBy[dateSearchConst.between],
  ],
  'number': [
    {id: below, name: 'At Most'},
    {id: above, name: 'At Least'},
    {id: between, name: 'Between'}
  ],
  'note': [
    {id: withValue, name: 'With Notes'},
    {id: withoutValue, name: 'Without Notes'}
  ],
  'includeAll': {id: 'includeAll', name: 'Include All'},
  'includeAny': {id: 'includeAny', name: 'Include Any'},
  'exclude': {id: 'exclude', name: 'Exclude'},
  'withValue': {id: withValue, name: 'Any Value'},
  'withoutValue': {id: withoutValue, name: 'No Value'},
  'dateDefaultValue': {id: 'null', name: 'Any Range'},
  'textDefaultValue': {id: 'null', name: 'Any Value'},
  'activityDefaultValue': {id: 'null', name: 'Any Activity'},
  'includeAllActivity': {id: 'includeAll', name: 'Has All'},
  'includeAnyActivity': {id: 'includeAny', name: 'Has Any'},
  'excludeActivity': {id: 'exclude', name: 'Doesn\'t Have '},
  [dateSearchConst.inTheLast]: dateRangeChoicesKeyBy[dateSearchConst.inTheLast],
  [dateSearchConst.inTheNext]: dateRangeChoicesKeyBy[dateSearchConst.inTheNext],
  [dateSearchConst.inTheLastCalendar]: dateRangeChoicesKeyBy[dateSearchConst.inTheLastCalendar],
  [dateSearchConst.inTheCurrentCalendar]: dateRangeChoicesKeyBy[dateSearchConst.inTheCurrentCalendar],
  [dateSearchConst.inTheNextCalendar]: dateRangeChoicesKeyBy[dateSearchConst.inTheNextCalendar]
};

const maxDistance= 10;
const maxDistanceChoices =  {
  0: 'Exact location',
  5: 'Within 5 miles',
  10: 'Within 10 miles',
  15: 'Within 15 miles',
  25: 'Within 25 miles',
  50: 'Within 50 miles',
  100: 'Within 100 miles',
  150: 'Within 150 miles',
  200: 'Within 200 miles',
  250: 'Within 250 miles',
  300: 'Within 300 miles'
};
const sortByChoices = {
  '' :          'Relevance',
  'createdAt' : 'Date Added',
  'updatedAt': 'Last Updated'
};

const placementSortByChoices = {
  '' :                            'Relevance',
  'accountingWorkerId':           'Accounting Worker ID',
  'accountingCompanyId':          'Accounting Company ID',
  'accountCoordinatorUser':       'Account Coordinator',
  'accountManagerUser':           'Account Manager',
  'accountManagerPercentage':     'Account Manager %',
  'backupApproverContact':        'Backup Approver',
  'createdAt':                    'Date Added',
  'backOfficeOwnerUser':          'Back Office Owner',
  'contractorEngagementUser':     'Contractor Engagement',
  'baseSalary':                   'Base Salary',
  'billingContact':               'Billing Contact',
  'billingLocation':              'Billing Location',
  'billingSchedule':              'Billing Schedule',
  'billRate':                     'Bill Rate',
  'businessUnit':                 'Business Unit',
  'candidate':                    'Candidate',
  'candidateSource':              'Candidate Source',
  'clientContact':                'Client Contact',
  'companyName':                  'Company',
  'companyType':                  'Company Type',
  'complianceStatus':             'Compliance Status',
  'coRecruiter':                  'Co-Recruiter',
  'coRecruiterPercentage':        'Co-Recruiter %',
  'costCenter':                   'Cost Center',
  'currencyType':                 'Currency Type',
  'division':                     'Division',
  'effectiveDate':                'Effective Date',
  'scheduledEndDate':             'End Date',
  'flatFee':                      'Flat Fee',
  'glAccount':                    'GL Account #',
  'hireType':                     'Hire Type',
  'numHoursPerWeek':              'Hours per Week',
  'jobTitle':                     'Job',
  'practiceArea':                 'Job Category',
  'updatedAt':                    'Last Updated',
  'markup':                       'Markup (%)',
  'mspContact':                   'MSP Contact',
  'originalStartDate':            'Original Start Date',
  'otBillRate':                   'OT Bill Rate',
  'otPayRate':                    'OT Pay Rate',
  'otMarkup':                     'OT Markup (%)',
  'overtimeType':                 'Overtime Type',
  'patientCareType':              'Patient Care Type',
  'payModel':                     'Pay Model',
  'payRate':                      'Pay Rate',
  'permanentFee':                 'Perm Fee (%)',
  'placementId':                  'Placement Id',
  'placementTitle':               'Placement Title',
  'placementType':                'Placement Type',
  'programClient':                'Program Client',
  'programMsp':                   'Program MSP',
  'purchaseOrder':                'Purchase Order #',
  'primaryRecruiter':             'Recruiter',
  'primaryRecruiterPercentage':   'Recruiter %',
  'primarySalesperson':           'Salesperson',
  'primarySalespersonPercentage': 'Salesperson %',
  'scheduledStartDate':           'Start Date',
  'status':                       'Status',
  'timeApproverContact':          'Time Approver',
  'vmsName' :                     'VMS Name',
  'vmsJobId' :                    'VMS Job ID',
  'vmsPlacementId' :              'VMS Placement ID',
  'workMode':                     'Work Mode',
  'workLocation':                 'Work Location'
};

const placementSortByMap = {
  'createdAt': 'createdAt',
  'updatedAt': 'updatedAt',
  'candidate': 'candidateFirstName,candidateLastName',
  'placementTitle': 'placementTitle',
  'companyName': 'companyName',
  'placementType': 'placementType',
  'status': 'status',
  'scheduledStartDate': 'scheduledStartDate',
  'scheduledEndDate': 'scheduledEndDate',
  'effectiveDate': 'effectiveDate',
  'originalStartDate': 'originalStartDate',
  'jobTitle': 'jobTitle',
  'companyType': 'companyType',
  'placementId': 'placementId',
  'clientContact': 'clientContactFirstName,clientContactLastName',
  'mspContact': 'mspContactFirstName,mspContactLastName',
  'programClient': 'programClient',
  'programMsp': 'programMsp',
  'primaryRecruiter': 'recruiterFirstName,recruiterLastName',
  'primaryRecruiterPercentage': 'recruiterPercentage',
  'coRecruiter': 'coRecruiterFirstName,coRecruiterLastName',
  'coRecruiterPercentage': 'coRecruiterPercentage',
  'primarySalesperson': 'salespersonFirstName,salespersonLastName',
  'primarySalespersonPercentage': 'primarySalespersonPercentage',
  'backOfficeOwnerUser': 'backOfficeOwnerFirstName,backOfficeOwnerLastName',
  'contractorEngagementUser': 'contractorEngagementUserFirstName,contractorEngagementUserLastName',
  'accountManagerUser': 'accountManagerFirstName,accountManagerLastName',
  'accountManagerPercentage': 'accountManagerPercentage',
  'accountCoordinatorUser': 'accountCoordinatorFirstName,accountCoordinatorLastName',
  'candidateSource': 'candidateSource',
  'workLocation': 'workCity,workState',
  'billingLocation': 'billingCity,billingState',
  'workMode': 'workMode',
  'jobCategory': 'jobCategory',
  'businessUnit': 'businessUnitName',
  'division': 'division',
  'hireType': 'hireType',
  'payModel': 'contractPayModel,permanentPayModel',
  'billRate': 'billRate',
  'payRate': 'payRate',
  'markup': 'markup',
  'holidayBillRate': 'holidayBillRate',
  'holidayPayRate': 'holidayPayRate',
  'holidayMarkup': 'holidayMarkup',
  'onCallBillRate': 'onCallBillRate',
  'onCallPayRate': 'onCallPayRate',
  'onCallMarkup': 'onCallMarkup',
  'callBackBillRate': 'callBackBillRate',
  'callBackPayRate': 'callBackPayRate',
  'callBackMarkup': 'callBackMarkup',
  'orientationBillRate': 'orientationBillRate',
  'orientationPayRate': 'orientationPayRate',
  'orientationMarkup': 'orientationMarkup',
  'doubleTimeBillRate': 'doubleTimeBillRate',
  'doubleTimePayRate': 'doubleTimePayRate',
  'doubleTimeMarkup': 'doubleTimeMarkup',
  'baseSalary': 'baseSalary',
  'flatFee': 'flatFee',
  'permanentFee': 'permanentFee',
  'overtimeType': 'contractOvertimeType,permanentOvertimeType',
  'otBillRate': 'otBillRate',
  'otPayRate': 'otPayRate',
  'otMarkup': 'otMarkup',
  'currencyType': 'currencyType',
  'billingSchedule': 'billingSchedule',
  'numHoursPerWeek': 'numHoursPerWeek',
  'billingContact': 'billingContactFirstName,billingContactLastName',
  'accountingWorkerId':  'accountingWorkerId',
  'accountingCompanyId': 'accountingCompanyId',
  'timeApproverContact': 'timeApproverFirstName,timeApproverLastName',
  'backupApproverContact': 'backupApproverFirstName,backupApproverLastName',
  'costCenter': 'costCenter',
  'purchaseOrder': 'purchaseOrder',
  'glAccount': 'glAccount',
  'vmsName' : 'vmsName',
  'vmsJobId' : 'vmsJobId',
  'vmsPlacementId' : 'vmsPlacementId',
  'patientCareType': 'patientCareType',
  'complianceStatus': 'complianceStatus',
  '' :          'Relevance'
};

const sortByChoicesLookup = {
  'candidate': sortByChoices,
  'contact': sortByChoices,
  'job': sortByChoices,
  'placement': placementSortByChoices,
  'company': sortByChoices
};

const sortByMap = {
  'createdAt' : 'createdAt',
  'updatedAt': 'updatedAt',
};

const sortByMapLookup = {
  'candidate': sortByMap,
  'contact': sortByMap,
  'job': sortByMap,
  'placement': placementSortByMap,
  'company': sortByMap
};

const entityMap = {
  'candidate': 'candidates',
  'company': 'companies',
  'contact': 'contacts',
  'job': 'jobs',
  'placement': 'placements',
  'submission': 'submissions',
  'note': 'notes',
  'appointment': 'appointments',
  'attachment': 'attachments',
  'target': 'targets',
  'deal': 'deals',
};

const searchFilterTypes = {
  ALL: 'All',
  CANDIDATE: 'Candidate',
  JOB: 'Job',
  CONTACT: 'Contact',
  PLACEMENT: 'Placement',
  COMPANY: 'Company',
};

const entityTypePluralMap = {
  'candidates': 'candidates',
  'companies': 'company',
  'contacts': 'contact',
  'jobs': 'job',
  'placements': 'placement',
  'submissions': 'submission',
  'notes': 'note',
  'tasks': 'task',
  'appointments': 'appointment',
  'targets': 'target',
};

const defaultSearchResultsFieldsListLookup = {
  'job': [
    'jobId',
    'jobTitle',
    'jobType',
    'openStatus',
    'practiceArea',
    'location.city',
    'location.state',
    'patientCareType',
    'primaryRecruiter',
    'numOpenPositions',
    'submittalCap',
    'stringJobId',
    'vmsInfo',
    'closeReason',
    'badges',
    'lists',
    'rateInfo',
    'company',
    'contact',
    'programInfo',
    'owner',
    'postedAt',
    'createdAt',
    'updatedAt',
    'coRecruiters',
    'isRemote',
    'jobSummary',
    'coding',
    'dateOnHold',
    'dateOffHold',
    'dateFirstSubmission',
    'dateFirstClientSubmission',
    'dateFirstInterviewAppointment',
    'dateFirstPlacedSubmission',
    'totalTimeActive',
    'totalTimeOnHold',
    'dateClosed',
    'accountManager',
    'accountCoordinator',
    'salesperson',
    'jobSource'
  ],
  'placement': [
    'placementId',
    'jobTitle',
    'status',
    'placementType',
    'candidate.id',
    'candidate.candidateName',
    'badges',
    'candidate.accountingWorkerId',
    'job',
    'commission.primaryRecruiter',
    'updatedAt',
    'createdAt',
    'scheduledStartDate',
    'scheduledEndDate',
    'commission.accountManager',
    'commission.accountCoordinator',
    'commission.sourcer'
  ]
};

const searchResultsFieldsListLookup = {
  placement: {
    createdAt:                    'createdAt',
    updatedAt:                    'updatedAt',
    scheduledStartDate:           'scheduledStartDate',
    scheduledEndDate:             'scheduledEndDate',
    effectiveDate:                'effectiveDate',
    originalStartDate:            'originalStartDate',
    placementId:                  'placementId',
    billRate:                     'contractRateInfo.payModelDetails.billRate',
    payRate:                      'contractRateInfo.payModelDetails.payRate',
    markup:                       'contractRateInfo.payModelDetails.markup',
    holidayBillRate:              'contractRateInfo.payModelDetails.holidayBillRate',
    holidayPayRate:               'contractRateInfo.payModelDetails.holidayPayRate',
    holidayMarkup:                'contractRateInfo.payModelDetails.holidayMarkup',
    onCallBillRate:              'contractRateInfo.payModelDetails.onCallBillRate',
    onCallPayRate:               'contractRateInfo.payModelDetails.onCallPayRate',
    onCallMarkup:                'contractRateInfo.payModelDetails.onCallMarkup',
    callBackBillRate:              'contractRateInfo.payModelDetails.callBackBillRate',
    callBackPayRate:               'contractRateInfo.payModelDetails.callBackPayRate',
    callBackMarkup:                'contractRateInfo.payModelDetails.callBackMarkup',
    orientationBillRate:              'contractRateInfo.payModelDetails.orientationBillRate',
    orientationPayRate:               'contractRateInfo.payModelDetails.orientationPayRate',
    orientationMarkup:                'contractRateInfo.payModelDetails.orientationMarkup',
    doubleTimeBillRate:              'contractRateInfo.payModelDetails.doubleTimeBillRate',
    doubleTimePayRate:               'contractRateInfo.payModelDetails.doubleTimePayRate',
    doubleTimeMarkup:                'contractRateInfo.payModelDetails.doubleTimeMarkup',
    baseSalary:                   'permanentRateInfo.payModelDetails.payRate',
    flatFee:                      'permanentRateInfo.flatFee',
    permanentFee:                 'permanentRateInfo.permanentFee',
    otBillRate:                   'contractRateInfo.overtimeRate.billRate',
    otPayRate:                    'contractRateInfo.overtimeRate.payRate',
    otMarkup:                     'contractRateInfo.overtimeRate.markup',
    numHoursPerWeek:              'numHoursPerWeek',
    candidate:                    ['candidate.id', 'candidate.candidateName'],
    placementTitle:               'jobTitle',
    companyName:                  'job',
    placementType:                'placementType',
    status:                       'status',
    jobTitle:                     'job',
    companyType:                  'job',
    clientContact:                'contact',
    mspContact:                   'contact',
    programClient:                'programInfo',
    programMsp:                   'programInfo',
    primaryRecruiter:             'commission.primaryRecruiter',
    primaryRecruiterPercentage:   'commission.primaryRecruiterPercentage',
    coRecruiter:                  'commission.coRecruiter',
    coRecruiterPercentage:        'commission.coRecruiterPercentage',
    primarySalesperson:           'commission.primarySalesperson',
    primarySalespersonPercentage: 'commission.primarySalespersonPercentage',
    accountCoordinatorUser:       'commission.accountCoordinator',
    accountManagerUser:           'commission.accountManager',
    accountManagerPercentage:     'commission.accountManagerPercentage',
    backOfficeOwnerUser:          'backOfficeOwnerUser',
    contractorEngagementUser:     'contractorEngagementUser',
    candidateSource:              'candidate.sourceOption',
    workLocation:                 ['workLocation.city', 'workLocation.state'],
    billingLocation:              ['billingLocation.city', 'billingLocation.state'],
    workMode:                     'workMode',
    jobCategory:                  'job',
    businessUnit:                 'businessUnit',
    division:                     'division',
    hireType:                     'contractRateInfo.hireType',
    payModel:                     ['contractRateInfo.payModel', 'permanentRateInfo.payModel'],
    overtimeType:                 ['contractRateInfo.overtimeType', 'permanentRateInfo.overtimeType'],
    currencyType:                 'currencyType',
    billingSchedule:              'contractRateInfo.billingSchedule',
    billingContact:               'billingContact',
    accountingWorkerId:           'candidate.accountingWorkerId',
    accountingCompanyId:          'accountingCompanyId',
    timeApproverContact:          'timeApproverContact',
    backupApproverContact:        'backupApproverContact',
    costCenter:                   'costCenter',
    purchaseOrder:                'purchaseOrder',
    glAccount:                    'glAccount',
    vmsName:                      'vmsInfo.name',
    vmsJobId:                     'vmsInfo.jobId',
    vmsPlacementId:               'vmsInfo.placementId',
    patientCareType:              'patientCareType',
    complianceStatus:             'complianceStatus',
  },
  job: {
    jobId:                           'jobId',
    jobTitle:                        'jobTitle',
    jobType:                         'jobType',
    openStatus:                      'openStatus',
    practiceArea:                    'practiceArea',
    location:                        ['location.city', 'location.state'],
    patientCareType:                 'patientCareType',
    closeReason:                     'closeReason',
    primaryRecruiter:                'primaryRecruiter',
    numOpenPositions:                'numOpenPositions',
    submittalCap:                    'submittalCap',
    stringJobId:                     'stringJobId',
    vmsName:                         'vmsInfo',
    vmsJobId:                        'vmsInfo',
    badges:                          'badges',
    lists:                           'lists',
    rateType:                        'rateInfo',
    billRate:                        'rateInfo',
    overtimeType:                    'rateInfo',
    currencyType:                    'rateInfo',
    payRate:                         'rateInfo',
    markup:                          'rateInfo',
    totalCompensation:               'rateInfo',
    baseSalary:                      'rateInfo',
    permRate:                        'rateInfo',
    grossProfit:                     'rateInfo',
    company:                         'company',
    contact:                         'contact',
    programInfo:                     'programInfo',
    owner:                           'owner',
    postedAt:                        'postedAt',
    createdAt:                       'createdAt',
    updatedAt:                       'updatedAt',
    coRecruiters:                    'coRecruiters',
    isRemote:                        'isRemote',
    jobSummary:                      'jobSummary',
    coding:                          'coding',
    dateOnHold:                      'dateOnHold',
    dateOffHold:                     'dateOffHold',
    dateFirstSubmission:             'dateFirstSubmission',
    dateFirstClientSubmission:       'dateFirstClientSubmission',
    dateFirstInterviewAppointment:   'dateFirstInterviewAppointment',
    dateFirstPlacedSubmission:       'dateFirstPlacedSubmission',
    totalTimeActive:                 'totalTimeActive',
    totalTimeOnHold:                 'totalTimeOnHold',
    jobSource:                       'jobSource',

  }
};

const defaultSearchCriteria = {
  maxDistance
};

const viewTypeOptions= {
  'job' : {
    'jobDetail': 'Job Detail',
    'jobTable': 'Job Table',
    'subTable': 'Submission Table',
    'subKanban': 'Submission Kanban'
  },
  'placement': {
    'placementDetail': 'Detail',
    'placementTable': 'Table'
  },
  'submission': {
    'subTable': 'Submission Table',
    'subKanban': 'Submission Kanban'
  },
  'deal': {
    'dealTable': 'Deal Table',
    'dealKanban': 'Deal Kanban'
  }
};

const viewTypeMap = {
  'jobs': 'job',
  'submissions': 'sub',
  'placements': 'placement'
};

const savedTableColumnsToDbLookup = {
  'candidate': false,
  'contact': false,
  'company': false,
  'job': true,
  'placement': true
};

const defaultAdditionalCriteriaLookups = {
  job: [
    {
      name: 'assignedToUserIds',
      criteria: { option: 'includeAny', value: ['current'] }
    },
    {
      name: 'practiceAreaIds',
      criteria: { option: withValue }
    },
    {
      name: 'openStatus',
      criteria: { option: 'includeAny', value: ['OPEN'] }
    },
    {
      name: 'dateCreatedRange',
      criteria: { option: 'inTheLast', value: [43200] }
    }
  ],
  placement : [
    {
      name: 'businessUnitIds',
      criteria: { option: withValue }
    },
    {
      name: 'placementType',
      criteria: { option: 'includeAny', value: ['CONTRACT', 'PERMANENT'] }
    },
    {
      name: 'status',
      criteria: { option: 'includeAny', value: ['PENDING', 'ACTIVE'] }
    },
    {
      name: 'effectiveDate',
      criteria: { option: 'inTheCurrentCalendar', value: ['QUARTER'] }
    }
  ]
};

const searchConst = {
  entityTypeAheadFilterBy,
  minLength,
  maxResults,
  delay,
  below,
  above,
  [withValue]: withValue,
  [withoutValue]: withoutValue,
  before: dateSearchConst.before,
  after: dateSearchConst.after,
  between: dateSearchConst.between,
  inTheLast: dateSearchConst.inTheLast,
  inTheNext: dateSearchConst.inTheNext,
  inTheLastCalendar: dateSearchConst.inTheLastCalendar,
  inTheCurrentCalendar: dateSearchConst.inTheCurrentCalendar,
  inTheNextCalendar: dateSearchConst.inTheNextCalendar,
  dateRangeOptions: dateSearchConst.dateRangeOptions,
  searchOptions,
  searchFilterTypes,
  maxDistanceChoices,
  maxDistance,
  entityMap,
  entityTypePluralMap,
  bulkActionLimitCount,
  sendEmailLimitCount,
  addToListLimitCount,
  searchResultsFieldsListLookup,
  defaultSearchResultsFieldsListLookup,
  defaultSearchCriteria,
  viewTypeOptions,
  viewTypeMap,
  sortByMap,
  sortByChoicesLookup,
  sortByMapLookup,
  savedTableColumnsToDbLookup,
  defaultAdditionalCriteriaLookups
};
export default searchConst;
