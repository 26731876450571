import utils from '../../../libs/utils';
import ApiClient from '../../../services/ApiClient';
import {toast} from 'react-toastify';

export default class DropzoneService {
  static imageProcess(acceptedFiles, files, setFiles, props) {
    const newFiles = [];
    let newFileCount = acceptedFiles.length;
    acceptedFiles.forEach( async (file) => {
      const image = {
        status:     'CREATED',
        entityType: props.entityType,
        fileName:   file.name,
        fileSize:   file.size,
        image: file
      };

      try {
        const response = await ApiClient.imagesCreate({}, image, { headers: { 'Content-Type': 'multipart/form-data' } });
        newFiles.push({imageId: response.body.imageId, fileName: file.name});
        if (props.multiple) {
          setFiles(files.concat([{imageId: response.body.imageId, fileName: file.name}]));
        } else {
          setFiles([{imageId: response.body.imageId, fileName: file.name}]);
        }
        
        if ( newFiles.length === newFileCount) {
          if (props.multiple) {
            props.callback({[props.name]: [...files, ...newFiles]});
            setFiles([...files, ...newFiles]);
          } else {
            props.callback({[props.name]: newFiles[0]});
            setFiles(newFiles);
          }
        }
      } catch (error) {
        console.log(error);
        newFileCount--;
      }
    });
  };

  static resumeProcess(acceptedFiles, files, setFiles, targetElemId, parseResume, props) {
    const newFiles = [];
    let newFileCount = acceptedFiles.length;
    acceptedFiles.forEach(async (file) => {
      const attachment = {
        name:       utils.parsedFileName(file.name),
        status:     'CREATED',
        entityType: props.entityType,
        attachmentType: 'RESUME',
        scope: 'PUBLIC',
        fileName:   file.name,
        fileSize:   file.size,
        attachment: file
      };

      // call attachment api to create attachment,
      // then add attachmentId to entity record like candidate
      try {
        const fileName =  file.name;
        const extension = fileName.split('.').pop().toLowerCase();
        const expectedFileExtensions = ['pdf', 'doc', 'docx'];

        if (!expectedFileExtensions.includes(extension)) {
          const msg = `File type :  ${extension} is not allowed`;
          toast.error(msg, {
            position: 'top-right',
          });
          return;
        }
        const response = await ApiClient.attachmentsCreate({}, attachment, { headers: { 'Content-Type': 'multipart/form-data' } });
        newFiles.push({attachmentId: response.body.attachmentId, fileName: file.name});
        if (props.multiple) {
          setFiles(files.concat([{attachmentId: response.body.attachmentId, fileName: file.name}]));
        } else {
          setFiles([{attachmentId: response.body.attachmentId, fileName: file.name}]);
        }

        if ( newFiles.length === newFileCount) {
          if (props.multiple) {
            props.callback({[props.name]: [...files, ...newFiles]});
            setFiles([...files, ...newFiles]);
          } else {
            if(props.dropType=== 'resume-parsing' && !['resume', 'replaceResume'].includes(targetElemId)) {
              await parseResume({[props.name]: newFiles[0]});
              window.amplitudeLogEvent('create', {action: 'drop resume', object: 'candidate'});
            } else {
              props.callback({[props.name]: newFiles[0]});
              if(props.name === 'resume') {
                window.amplitudeLogEvent('create', {action: 'upload resume', object: (props.entityType || '').toLowerCase()});
              }
            }
            setFiles(newFiles);
          }
        }
      } catch (error) {
        console.log(error);
        newFileCount--;
      }
    });
  };

  static documentProcess(acceptedFiles, files, setFiles, addEditMode, props) {
    const newFiles = acceptedFiles.map((file) => {
      return {
        name:       utils.parsedFileName(file.name),
        status:     'ACTIVE',
        entityType: props.entityType,
        entityId: props.entityId,
        attachmentType: 'GENERAL',
        scope: 'PUBLIC',
        fileName:   file.name,
        fileSize:   file.size,
        attachment: file
      };
    });
    setFiles([...files, ...newFiles]);

    if ([...files, ...newFiles].length) {
      addEditMode('files-list-body');
    }
  };

  static async massImportProcess(acceptedFiles, files, setFiles, props) {
    const file = acceptedFiles[0];
    const newFile = {
      status:     'PENDING',
      fileName:   file.name,
      fileSize:   file.size,
      file: file
    };
    newFile.fileHeaderInfo = await DropzoneService.csvFileProcess(file);

    props.callback({[props.name]: newFile});
    setFiles([newFile]);
  };

  static csvFileProcess(file) {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onabort   = () => console.log('file reading was aborted');
      reader.onerror   = () => console.log('file reading has failed');
      reader.onloadend = function (evt) {
        let data = evt.target.result;
        let byteLength = data.byteLength;

        // Avoid converting entire ArrayBuffer to string, so not all of ArrayBuffer would have to come into memory
        let ui8a = new Uint8Array(data, 0);

        let headerString = '';

        for (let i = 0; i < byteLength; i++) {
          let char = String.fromCharCode(ui8a[i]);

          if (char.match(/[^\r\n]+/g) !== null) {
            headerString += char;
          } else {
            break;
          }
        }

        // console.log(headerString.split(','));
        resolve(headerString.split(','));
      };

      reader.readAsArrayBuffer(file);
    });
  };
}
