import {cloneDeep, compact, get, has, isEmpty, pick, set} from '../../../libs/lodash';
import utils from '../../../libs/utils';
import {fieldsRequiredEffectiveDate} from '../../../constants/placementConst';

const CONTRACT = 'CONTRACT';
const PERMANENT = 'PERMANENT';
const PAYROLL = 'PAYROLL';
const EMBEDDED = 'EMBEDDED';


const prepareForRenderReports = (data) => {
  const visibility = utils.prepareRecordVisibility(data);
  return {
    ...data,
    visibility,
    id: data.reportId
  };
};

const prepareTypeAheadEntity = (entity, entityType) => {
  if (isEmpty(entity)) {
    return {};
  } else {
    entityType = entityType || '';
    return {
      ...entity,
      entityType: entityType.toUpperCase(),
      entityId: entity[`${entityType.toLowerCase()}Id`] || entity.id,
      fullName: utils.shortName(get(entity, `${entityType.toLowerCase()}Name`)),
    };
  }
};

const prepareAutoFillFromJob = (job, entity) => {
  const jobTitle = get(job, 'jobTitle');

  const businessUnit = job.businessUnit;
  const businessUnitId = get(job, 'businessUnit.id');

  const contact = prepareTypeAheadEntity(job.contact, 'CONTACT');
  const contactId = get(job, 'contact.contactId');

  const billingContact = prepareTypeAheadEntity(job.contact, 'CONTACT');
  const billingContactId = get(job, 'contact.contactId');

  // const backOfficeOwnerUserId = get(job, 'owner.userId');

  const companyId = get(job, 'company.companyId');
  const company = get(job, 'company');

  // for program company
  let programInfo;
  if(get(job, 'company.companyType') === 'PROGRAM') {
    programInfo = get(job, 'programInfo');
  }

  const scheduledStartDate = job.expectedStartDate;
  const scheduledEndDate = job.expectedEndDate;

  const placementType = job.jobType === 'PERMANENT' ? 'PERMANENT' : 'CONTRACT';
  const currencyType = get(job, 'rateInfo.currencyType');
  const numHoursPerWeek = get(job, 'rateInfo.numHoursPerWeek');

  // Commission
  const primaryRecruiterId = get(job, 'primaryRecruiter.userId');
  const coRecruiterId = get(job, 'coRecruiters[0].userId');
  const primarySalespersonId = get(job, 'salesperson.userId');
  const accountCoordinatorUserId = get(job, 'accountCoordinator.userId');
  const accountManagerUserId = get(job, 'accountManager.userId');
  const accountManagerPercentage = 0;
  const primaryRecruiterPercentage = 50;
  const coRecruiterPercentage = 0;
  const primarySalespersonPercentage = 50;

  const overtimeType = get(job, 'rateInfo.overtimeType');
  const payRate = get(job, 'rateInfo.payRate');

  const status = 'PENDING';

  // PERMANENT
  // const totalCompensation = get(job, 'rateInfo.totalCompensation');
  const baseSalary = get(job, 'rateInfo.baseSalary');
  const permFee = get(job, 'rateInfo.permRate');
  const grossProfit = get(job, 'rateInfo.grossProfit');
  const flatFee = get(job, 'flatFee');
  const permPayModel = 'SALARIED_PER_YEAR';

  // CONTRACT
  const billRate = get(job, 'rateInfo.billRate');
  const markup = get(job, 'rateInfo.markup');
  const durationUnit = get(job, 'rateInfo.rateType');
  const perDiem = false;
  const stage = 'ONBOARDING';

  let contractPayModel;
  switch (durationUnit) {
  case 'YEAR':
    contractPayModel = 'TIMECARD_PER_YEAR';
    break;
  case 'WEEK':
    contractPayModel = 'TIMECARD_PER_WEEK';
    break;
  case 'DAY':
    contractPayModel = 'TIMECARD_PER_DAY';
    break;
  case 'HOUR':
  default:
    contractPayModel = 'TIMECARD_PER_HOUR';
    break;
  }

  const coding = utils.prepareCodingTransform(job.coding);
  const vmsInfo = cloneDeep(job.vmsInfo);
  const workLocation = cloneDeep(job.location);
  const billingLocation = cloneDeep(job.location);

  const taxCity = get(job, 'location.city');
  const taxState = get(job, 'location.state');

  const patientCareType = job.patientCareType;
  const complianceStatus = job.complianceStatus;

  const updatedValues = {
    jobTitle, businessUnitId, businessUnit,
    contact, contactId, billingContact, billingContactId, companyId, company,
    scheduledStartDate, scheduledEndDate,
    placementType, currencyType, numHoursPerWeek, status, programInfo,

    'commission.primaryRecruiterId': primaryRecruiterId, 'commission.coRecruiterId': coRecruiterId,
    'commission.primarySalespersonId': primarySalespersonId,
    'commission.primaryRecruiterPercentage': primaryRecruiterPercentage, 'commission.coRecruiterPercentage': coRecruiterPercentage,
    'commission.primarySalespersonPercentage': primarySalespersonPercentage, 'commission.accountCoordinatorUserId': accountCoordinatorUserId,
    'commission.accountManagerUserId': accountManagerUserId, 
    'commission.accountManagerPercentage': accountManagerPercentage,
    'permanentRateInfo.overtimeType': overtimeType, 'permanentRateInfo.payModel': permPayModel,
    'permanentRateInfo.payModelDetails.payRate': baseSalary, 'permanentRateInfo.permanentFee': permFee,
    //'permanentRateInfo.grossProfit': grossProfit, 'permanentRateInfo.flatFee': flatFee,
    'permanentRateInfo.flatFee': grossProfit || flatFee, 

    'contractRateInfo.overtimeType': overtimeType,
    // 'contractRateInfo.overtimeRate.payRate': payRate,
    //'contractRateInfo.overtimeRate.billRate': billRate, 'contractRateInfo.overtimeRate.markup': markup,
    'contractRateInfo.payModelDetails.payRate': payRate, 'contractRateInfo.payModelDetails.billRate': billRate,
    'contractRateInfo.payModelDetails.markup': markup, 'contractRateInfo.payModelDetails.durationUnit': durationUnit,
    'contractRateInfo.payModel': contractPayModel, 'contractRateInfo.perDiem': perDiem, 'contractRateInfo.stage': stage,

    coding, vmsInfo, workLocation, billingLocation, taxCity, taxState, patientCareType, complianceStatus,
  };

  return {...updatedValues, notifyTargetIds: utils.prepareNotifyTargetIds({...entity, ...updatedValues})};
};

function prepareForRenderPlacement(data) {
  data.candidate = prepareTypeAheadEntity(data.candidate, 'CANDIDATE');
  data.job = prepareTypeAheadEntity(data.job, 'JOB');

  if (!data.companyId && data.job) {
    if (data.job.company) {
      data.companyId = data.job && data.job.company.companyId;
    } else {
      data.companyId = data.job && data.job.companyId;
    }
  }
  if (!data.contactId && data.contact) {
    data.contactId = data.contact.contactId;
  }

  data.badgeIds = (data.badges || []).map((badge) => badge.id);

  data.candidateId = data.candidate && (data.candidate.candidateId||data.candidate.id);
  data.jobId = data.job && (data.job.jobId||data.job.id);

  data.businessUnitId = data.businessUnitId || get(data, 'businessUnit.id');
  data.backOfficeOwnerUserId = data.backOfficeOwnerUserId || get(data, 'backOfficeOwnerUser.userId');
  data.contractorEngagementUserId = data.contractorEngagementUserId || get(data, 'contractorEngagementUser.userId');
  data.billingLocationSameAsWorkLocation = isEmpty(data.billingLocation);

  data.contractRateInfo = data.contractRateInfo || {};
  data.permanentRateInfo = data.permanentRateInfo || {};

  data.coding = utils.prepareCodingTransform(data.coding);

  data.contact = prepareTypeAheadEntity(data.contact, 'CONTACT');
  data.billingContact = prepareTypeAheadEntity(data.billingContact, 'CONTACT');
  data.timeApproverContact = prepareTypeAheadEntity(data.timeApproverContact, 'CONTACT');
  data.backupApproverContact = prepareTypeAheadEntity(data.backupApproverContact, 'CONTACT');

  data.billingContactId = get(data, 'billingContact.contactId');
  data.timeApproverContactId = get(data, 'timeApproverContact.contactId');
  data.backupApproverContactId = get(data, 'backupApproverContact.contactId');

  // deal inactive user
  data.origBackOfficeOwner = utils.convertObjectToOptionFormat(data.backOfficeOwnerUser);
  data.origContractorEngagement = utils.convertObjectToOptionFormat(data.contractorEngagementUser);
  if (data.commission) {
    data.origPrimaryRecruiter = utils.convertObjectToOptionFormat(data.commission.primaryRecruiter);
    data.origCoRecruiter = utils.convertObjectToOptionFormat(data.commission.coRecruiter);
    data.origPrimarySalesperson = utils.convertObjectToOptionFormat(data.commission.primarySalesperson);
    data.origAccountCoordinator = utils.convertObjectToOptionFormat(data.commission.accountCoordinator);
    data.origAccountManager = utils.convertObjectToOptionFormat(data.commission.accountManager);
    data.origSourcer = utils.convertObjectToOptionFormat(data.commission.sourcer);
  }

  if (!isEmpty(data.contact) && !get(data, 'contact.name')) {
    data.contact = {
      contactId: get(data, 'contact.contactId'),
      name: compact([
        get(data, 'contact.contactName.preferredName') || get(data, 'contact.contactName.firstName'),
        get(data, 'contact.contactName.lastName')
      ]).join(' ')
    };
  }

  if (get(data, 'job.companyType') === 'PROGRAM') {
    if (!isEmpty(data.programInfo) ) {
      data.programInfo = {
        ...data.programInfo,
        clientContact: {
          ...get(data, 'programInfo.clientContact'),
          name: get(data, 'programInfo.clientContact.name' ) || compact([
            get(data, 'programInfo.clientContact.contactName.preferredName') || get(data, 'programInfo.clientContact.contactName.firstName'),
            get(data, 'programInfo.clientContact.contactName.lastName')
          ]).join(' '),
        },
        mspContact: {
          ...get(data, 'programInfo.mspContact'),
          name: get(data, 'programInfo.mspContact.name' ) || compact([
            get(data, 'programInfo.mspContact.contactName.preferredName') || get(data, 'programInfo.mspContact.contactName.firstName'),
            get(data, 'programInfo.mspContact.contactName.lastName')
          ]).join(' ')
        }
      };
    }
  }
  if(data.vmsInfo) {
    data['vmsInfo.name'] = data.vmsInfo.name;
    data['vmsInfo.jobId'] = data.vmsInfo.jobId;
    data['vmsInfo.placementId'] = data.vmsInfo.placementId;
  }
  return data;
}

function prepareForApiPlacement(data) {
  const apiData = pick(cloneDeep(data), [
    'contactId',
    'billingContactId',
    'jobTitle',
    'status',
    'backOfficeOwnerUserId',
    'contractorEngagementUserId',
    'businessUnitId',
    'badgeIds',
    'division',
    'closeReason',
    'scheduledStartDate',
    'scheduledEndDate',
    'originalStartDate',
    'effectiveDate',
    'numHoursPerWeek',
    'currencyType',
    'placementType',
    'workLocation',
    'workMode',
    'taxCity',
    'taxState',
    'costCenter',
    'purchaseOrder',
    'glAccount',
    'commission',
    'vmsInfo',
    'supplementalDocuments',
    'auditLogComment',
    'coding.industryIds',
    'coding.jobFunctionIds',
    'coding.departmentIds',
    'coding.skillIds',
    'coding.highlightedSkills',
    'coding.phcCareerCodeIds',
    'coding.phcFacilityTypeIds',
    'accountingCompanyId',
    'timeApproverContactId',
    'backupApproverContactId',
    'patientCareType',
    'complianceStatus',
    'equipmentAllocated',
    'equipmentReturn'
  ]);

  if (has(data, 'status') && !['CLOSED', 'NO_START', 'TERMINATED'].includes(data.status) && data.closeReason !== null) {
    apiData.closeReason = null;
  } else if (['CLOSED', 'NO_START', 'TERMINATED'].includes(data.status)) {
    apiData.closeReason = data.closeReason;
  }

  if (has(data, 'candidate')) {
    apiData.candidateId = data.candidate.candidateId || data.candidate.id;
  }

  if (has(data, 'job')) {
    apiData.jobId = data.job.jobId || data.job.id;
  }

  if (has(data, 'contact')) {
    apiData.contactId = get(data, 'contact.contactId');
  }

  if (has(data, 'billingContact')) {
    apiData.billingContactId = get(data, 'billingContact.contactId', null);
  }

  if (has(data, 'timeApproverContact')) {
    apiData.timeApproverContactId = get(data, 'timeApproverContact.contactId', null);
  }

  if (has(data, 'backupApproverContact')) {
    apiData.backupApproverContactId = get(data, 'backupApproverContact.contactId', null);
  }

  if (get(apiData, 'scheduledStartDate')) {
    apiData.scheduledStartDate = utils.dateOnlyForApi(apiData.scheduledStartDate);
  }

  if (get(apiData, 'scheduledEndDate')) {
    apiData.scheduledEndDate = utils.dateOnlyForApi(apiData.scheduledEndDate, false);
  }

  if (get(apiData, 'originalStartDate')) {
    apiData.originalStartDate = utils.dateOnlyForApi(apiData.originalStartDate);
  }

  if (get(apiData, 'effectiveDate')) {
    apiData.effectiveDate = utils.dateOnlyForApi(apiData.effectiveDate);
  }

  if (apiData.equipmentAllocated === null && apiData.equipmentReturn !== undefined) {
    apiData.equipmentReturn = null;
  } else {
    apiData.equipmentReturn = data.equipmentReturn;
  }
 
  if (apiData.placementType === CONTRACT || apiData.placementType === PAYROLL) {
    apiData.contractRateInfo = data.contractRateInfo;
  } else if (apiData.placementType === PERMANENT || apiData.placementType === EMBEDDED) {
    apiData.permanentRateInfo = data.permanentRateInfo;
  }

  if (get(apiData, 'contractRateInfo.overtimeType') === 'EXEMPT') {
    set(apiData, 'contractRateInfo.overtimeRate.payRate', null);
    set(apiData, 'contractRateInfo.overtimeRate.billRate', null);
    set(apiData, 'contractRateInfo.overtimeRate.markup', null);
  }

  if (!data.billingLocationSameAsWorkLocation && !isEmpty(data.billingLocation)) {
    apiData.billingLocation = data.billingLocation;
  }

  if (has(apiData, 'supplementalDocuments')) {
    for (let i = 0; i < apiData.supplementalDocuments.length; i++) {
      apiData.supplementalDocuments[i] = {attachmentId: +apiData.supplementalDocuments[i].attachmentId};
    }
  }

  if (has(apiData, 'auditLogComment')) {
    apiData.auditLogComment = encodeURIComponent(apiData.auditLogComment);
  }

  if (has(apiData, 'commission.primaryRecruiter')) {
    delete apiData.commission.primaryRecruiter;
  }

  if (has(apiData, 'commission.coRecruiter')) {
    delete apiData.commission.coRecruiter;
  }

  if (has(apiData, 'commission.primarySalesperson')) {
    delete apiData.commission.primarySalesperson;
  }

  if (has(apiData, 'commission.accountCoordinator')) {
    delete apiData.commission.accountCoordinator;
  }

  if (has(apiData, 'commission.accountManager')) {
    delete apiData.commission.accountManager;
  }

  if (has(apiData, 'commission.sourcer')) {
    delete apiData.commission.sourcer;
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  if (get(apiData, 'workLocation')) {
    apiData.workLocation= utils.addressTransform(apiData.workLocation);
  }

  if (get(apiData, 'billingLocation')) {
    apiData.billingLocation= utils.addressTransform(apiData.billingLocation);
  }

  if (has(data, 'programInfo')) {
    if (get(data, 'job.companyType') === 'PROGRAM' || get(data, 'job.company.companyType') === 'PROGRAM') {
      apiData.programInfo = {
        mspCompanyId: get(data, 'programInfo.mspCompany.companyId'),
        mspContactId: get(data, 'programInfo.mspContact.contactId'),
        clientCompanyId: get(data, 'programInfo.clientCompany.companyId'),
        clientContactId: get(data, 'programInfo.clientContact.contactId')
      };
      apiData.contactId = get(data, 'programInfo.mspContact.contactId');
    } else {
      apiData.programInfo = {
        mspCompanyId: null,
        mspContactId: null,
        clientCompanyId: null,
        clientContactId: null
      };
    }
  }

  return apiData;
}

function prepareEffectiveDateAsMandatory(values, updateData, handleChange){
  // ATS-1570 - Enforce effective date as mandatory for few key data updates on placement
  let found = false;
  let field = '';
  const emptyValues = ['', null, undefined];
  const keys = Object.keys(updateData);

  // check changed field
  for(let i=0; i<keys.length && !found; i++) {
    const index  = fieldsRequiredEffectiveDate.findIndex(fd => keys[i].indexOf(fd) !== -1);
    if(index !== -1) {
      field = keys[i];
      found = true;
      break;
    }
  }

  if(!found) {
    handleChange(updateData);
    return;
  }

  // time approver/back approver need to compare contactId
  if(['timeApproverContact', 'backupApproverContact'].includes(field)) {
    field = field + '.contactId';
  }

  // empty value or no change
  if ((emptyValues.includes(get(values, field)) && emptyValues.includes(get(updateData, field))) || get(values, field) === get(updateData, field)) {
    handleChange(updateData);
    return;
  }

  // status, scheduledEndDate will only make notification user and comment required
  if(['status', 'scheduledEndDate'].includes(field)) {
    handleChange({...updateData, requiredNotifyTargetIds: true, requiredComment: true});
  } else {
    handleChange({...updateData, effectiveDate: null, requiredEffectiveDate: true, requiredNotifyTargetIds: true, requiredComment: true});
  }
}

function checkPercentageInputValidation(values, entity) {
  const commissionFieldNames = ['commission.primaryRecruiterPercentage', 'commission.coRecruiterPercentage', 'commission.primarySalespersonPercentage', 'commission.accountManagerPercentage'];
  const fieldName = Object.keys(values)[0];
  const fieldValue = Object.values(values)[0];
  const elements = document.querySelectorAll('.commission-percentage-input .ant-input-number-input');

  const preparePercentageRange = (fieldName) => {
    return 100 - commissionFieldNames.reduce((acc, cur) => {
      if (cur !== fieldName) {
        return acc + get(entity, cur, 0);
      } else {
        return acc;
      }
    }, 0);
  };

  if (fieldValue === null) {
    elements.forEach((el) => {
      el.classList.add('is-invalid');
      el.setAttribute('invalid', 'true');
      el.setCustomValidity('Commission fields values must be 100 in total');
    });
  } else if (fieldValue !==  parseFloat(preparePercentageRange(fieldName).toFixed(2))) {
    elements.forEach((el) => {
      el.classList.add('is-invalid');
      el.setAttribute('invalid', 'true');
      el.setCustomValidity('Commission fields values must be 100 in total');
    });
  } else {
    elements.forEach((el) => {
      el.classList.remove('is-invalid');
      el.setAttribute('invalid', 'false');
      el.setCustomValidity('');
    });
  }
}

function doesExternalFilterPass() {
  return;
}

export {
  prepareForRenderReports,
  prepareForRenderPlacement,
  prepareForApiPlacement,
  prepareEffectiveDateAsMandatory,
  checkPercentageInputValidation,
  prepareAutoFillFromJob,
  doesExternalFilterPass
};
