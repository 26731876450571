import {groupBy, isEmpty, pick} from '../libs/lodash';
import ApiClient from './ApiClient';

class Lookups {
  static #lookups = {};
  static #lookupsGroupByEntityTypes = {};
  static #promiseCall = {};

  static extraCachingLookupsMap = {
    departments: 'departmentsLookup',
    industries: 'industriesLookup',
    jobFunctions: 'jobFunctionsLookup',
    phcCareerCodes: 'phcCareerCodesLookup',
    phcFacilityTypes: 'phcFacilityTypesLookup',
    skills: 'skillsLookup',
    activityTypes: 'activityTypesLookup',
    countries: 'countriesLookup',
  };

  static resetCachingLookupsMap = {
    users: 'usersLookup',
    teams: 'teamsLookup',
    businessUnits: 'businessUnitsLookup',
    sourceOptions: 'sourceOptionsLookup',
    practiceAreas: 'practiceAreasLookup',
    badges: 'badgesLookup',
    metrics: 'metricsLookup',
  };

  static get lookups() {
    return this.#lookups;
  }

  static get lookupsGroupByEntityTypes() {
    return this.#lookupsGroupByEntityTypes;
  }

  static loadLookupsFromLocalStorage() {
    const lookups = JSON.parse(window.localStorage.getItem('lookups'));

    if (lookups) {
      this.#lookups = lookups;
    }
  }

  static saveLookupsToLocalStorage(lookups) {
    window.localStorage.setItem('lookups', JSON.stringify(lookups));
  }

  static removeLookupsToLocalStorage() {
    window.localStorage.removeItem('lookups');
  }

  static async initialize() {
    await this.getAllLookups();
  }

  static async getAllLookups() {
    this.loadLookupsFromLocalStorage();

    if (isEmpty(this.#lookups)) {
      await this.fetchEnumsCachingLookup();
      // await this.fetchResetCachingLookup(Object.keys(this.resetCachingLookupsMap));
      // await this.fetchExtraCachingLookup(Object.keys(this.extraCachingLookupsMap));

      this.saveLookupsToLocalStorage(this.#lookups);
    }

    return this.#lookups;
  }

  static async getAllGroupByEntityTypes() {
    await this.fetchEnumsCachingLookup();

    return this.#lookupsGroupByEntityTypes;
  }

  static async get(name) {
    await this.fetchEnumsCachingLookup([name]);
    await this.fetchResetCachingLookup([name]);
    await this.fetchExtraCachingLookup([name]);

    return this.#lookups[name];
  }

  static set(name, lookupOptions) {
    this.#lookups[name] = lookupOptions;
  }

  static async getByEntityType(entityType) {
    await this.fetchEnumsCachingLookup();

    return this.#lookupsGroupByEntityTypes[entityType];
  }

  static async fetch({ names= [], entityTypes = [] }) {
    await this.fetchEnumsCachingLookup(names, entityTypes);
    await this.fetchResetCachingLookup(names);
    await this.fetchExtraCachingLookup(names);

    this.saveLookupsToLocalStorage(this.#lookups);

    return this.#lookups;
  }

  static buildQueryBodyForEnumsLookup(names= [], entityTypes = []) {
    let requestBody = null;

    if (isEmpty(this.#lookups)) {
      requestBody = {};
    } else {
      for (const entityType of entityTypes) {
        if (this.#lookupsGroupByEntityTypes[entityType]) {
          continue;
        }

        requestBody = requestBody || {};
        requestBody.entityTypes = requestBody.entityTypes ? [...requestBody.entityTypes, entityType] : [entityType];
      }

      for (const name of names) {
        if (this.resetCachingLookupsMap[name]) {
          continue;
        }

        if (this.#lookups[name]) {
          continue;
        }

        if (this.extraCachingLookupsMap[name]) {
          continue;
        }

        requestBody = requestBody || {};
        requestBody.lookupCategoryNames = requestBody.lookupCategoryNames ? [...requestBody.lookupCategoryNames, name] : [name];
      }
    }

    return requestBody;
  }

  static async fetchEnumsCachingLookup(names= [], entityTypes = []) {
    const requestBody = this.buildQueryBodyForEnumsLookup(names, entityTypes);

    if (!requestBody) {
      return {};
    }

    if (this.#promiseCall['lookupCategoriesLookup']) {
      return ;
    }

    let lookupsKeyByName = {};
    let lookupsGroupByEntityType = {};

    this.#promiseCall['lookupCategoriesLookup'] = ApiClient['lookupCategoriesLookup']({}, requestBody)
      .then(res => {
        lookupsKeyByName = {};
        lookupsGroupByEntityType = groupBy(res.obj, 'entityTypes');

        for (const lookupCategory of res.obj) {
          const { name, lookupItems } = lookupCategory;
          if (name === 'placementEquipmentAllocates'){
            const equipmentAllocates = lookupItems.filter(obj=> obj.isActive === true).map(ob => {
              return { id: ob.id, name:ob.name};
            });
            lookupsKeyByName[name] = [{id: 'NOT_APPLICABLE', name: 'Not applicable'}, ...equipmentAllocates];
            this.#lookups.placementEquipmentAllocates = [{id: 'NOT_APPLICABLE', name: 'Not applicable'}, ...equipmentAllocates];
          } else if (name === 'placementEquipmentReturns'){
            const equipmentReturns = lookupItems.filter(obj=> obj.isActive === true).map(ob => {
              return { id: ob.id, name:ob.name};
            });
            lookupsKeyByName[name] = [{id: 'NOT_APPLICABLE', name: 'Not applicable'}, ...equipmentReturns];
            this.#lookups.placementEquipmentReturns = [{id: 'NOT_APPLICABLE', name: 'Not applicable'}, ...equipmentReturns];
          } 
          else {
            lookupsKeyByName[name] = lookupItems;
            this.#lookups[name] = lookupItems;
          }
        }

        for (const entityType in lookupsGroupByEntityType) {
          this.#lookupsGroupByEntityTypes[entityType] = lookupsGroupByEntityType[entityType];
        }

        setTimeout(() => delete this.#promiseCall['lookupCategoriesLookup'], 5000);
      });

    await this.#promiseCall['lookupCategoriesLookup'];

    return { lookupsKeyByName, lookupsGroupByEntityType };
  }

  static async fetchExtraCachingLookup(names= []) {
    const promises = [];

    for (const name of names) {
      if (!this.extraCachingLookupsMap[name]) {
        continue;
      }

      if (this.#lookups[name]) {
        continue;
      }

      if (this.#promiseCall[this.extraCachingLookupsMap[name]]) {
        promises.push(this.#promiseCall[this.extraCachingLookupsMap[name]]);
        continue;
      }

      this.#promiseCall[this.extraCachingLookupsMap[name]] = ApiClient[this.extraCachingLookupsMap[name]]({}, {})
        .then(res => {
          if (name === 'countries') {
            this.#lookups.countries = [...res.obj.map(country => {
              return { ...country, id: country.name } ;
            }), {id: 'Other', name: 'Other'}];
          } else {
            this.#lookups[name] = res.obj;
          }

          setTimeout(() => delete this.#promiseCall[this.extraCachingLookupsMap[name]], 5000);
        });

      promises.push(this.#promiseCall[this.extraCachingLookupsMap[name]]);
    }

    await Promise.all(promises);

    return pick(this.#lookups, names);
  }

  static async fetchResetCachingLookup(names = []) {
    const promises = [];

    for (const name of names) {
      if (!this.resetCachingLookupsMap[name]) {
        continue;
      }

      if (this.#promiseCall[this.resetCachingLookupsMap[name]]) {
        promises.push(this.#promiseCall[this.resetCachingLookupsMap[name]]);
        continue;
      }

      this.#promiseCall[this.resetCachingLookupsMap[name]] = ApiClient[this.resetCachingLookupsMap[name]]({}, {})
        .then(res => {

          if (name === 'users') {
            this.#lookups.recruiters = res.obj.filter(user => user.isRecruiter && user.isActive);
            this.#lookups.users = res.obj.filter(user => user.isActive);
            this.#lookups.allUsers = res.obj;
          }
          else {
            this.#lookups[name] = res.obj;
          }

          setTimeout(() => delete this.#promiseCall[this.resetCachingLookupsMap[name]], 5000);
        });

      promises.push(this.#promiseCall[this.resetCachingLookupsMap[name]]);
    }

    await Promise.all(promises);
    return pick(this.#lookups, names);
  }

  static prepareParams(names = [], entityTypes = []) {
    const params = {
      names: [],
      entityTypes: [],
    };

    for (const name of names) {
      if (!this.resetCachingLookupsMap[name] && this.#lookups[name]) {
        continue;
      }
      params.names.push(name);
    }

    for (const entityType of entityTypes) {
      if (this.lookupsGroupByEntityTypes[entityType]) {
        continue;
      }
      params.entityTypes.push(entityType);
    }

    return params;
  }
}

export default Lookups;
